import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchStudentById, fetchDepartments, updateStudent } from '../../api/api'; // Adjust imports
import "./StudentForm.css";

const EditStudentProfile = () => {
    const { id } = useParams(); // Get the student ID from the URL
    const navigate = useNavigate();
    
    const [student, setStudent] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        dob: '',
        address: '',
        email: '',
        mobile: '',
        parent_name: '',
        academic_percentages: '',
        joining_year: '',
        department: '',
        semester_fee: '',
        profile_image: null,
    });

    useEffect(() => {
        const getStudent = async () => {
            try {
                const response = await fetchStudentById(id);
                setStudent(response);
                
                setFormData(prevFormData => ({
                    ...prevFormData,
                    first_name: response.first_name,
                    last_name: response.last_name,
                    dob: response.dob,
                    address: response.address,
                    email: response.email,
                    mobile: response.mobile,
                    parent_name: response.parent_name,
                    academic_percentages: response.academic_percentages,
                    joining_year: response.joining_year,
                    department: response.department,
                    semester_fee: response.semester_fee,
                    profile_image: null,
                }));
            } catch (error) {
                setError({ general: 'Error fetching student data' });
            } finally {
                setLoading(false);
            }
        };

        const getDepartments = async () => {
            try {
                const response = await fetchDepartments();
                setDepartments(response);
            } catch (error) {
                setError({ general: 'Error fetching departments' });
            }
        };

        getStudent();
        getDepartments();
    }, [id]);

    const validateForm = () => {
        const errors = {};

        if (!formData.first_name) errors.first_name = "First name is required";
        if (!formData.last_name) errors.last_name = "Last name is required";
        if (!formData.dob) errors.dob = "Date of birth is required";
        if (!formData.address) errors.address = "Address is required";

        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email is invalid";
        }

        if (!formData.mobile) {
            errors.mobile = "Mobile number is required";
        } else if (!/^[0-9]{10}$/.test(formData.mobile)) {
            errors.mobile = "Mobile number must be 10 digits";
        }

        if (!formData.parent_name) errors.parent_name = "Parent name is required";

        if (!formData.academic_percentages) {
            errors.academic_percentages = "Academic percentages are required";
        } else if (
            isNaN(formData.academic_percentages) ||
            formData.academic_percentages < 0 ||
            formData.academic_percentages > 100
        ) {
            errors.academic_percentages =
                "Academic percentages must be a number between 0 and 100";
        }

        if (!formData.joining_year) {
            errors.joining_year = "Joining year is required";
        } else if (!/^\d{4}$/.test(formData.joining_year)) {
            errors.joining_year = "Joining year must be a valid year (e.g., 2023)";
        }

        if (!formData.department) errors.department = "Department is required";

        if (!formData.semester_fee) {
            errors.semester_fee = "Semester fee is required";
        } else if (isNaN(formData.semester_fee) || formData.semester_fee <= 0) {
            errors.semester_fee = "Semester fee must be a positive number";
        }

        return errors;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setError(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        const formDataToSend = new FormData();

        // Add form data to FormData object
        for (const key in formData) {
            if (key === 'profile_image' && formData[key]) {
                formDataToSend.append(key, formData[key]);
            } else if (key !== 'profile_image') {
                formDataToSend.append(key, formData[key]);
            }
        }

        try {
            await updateStudent(id, formDataToSend);
            navigate(`/student/${id}`);
        } catch (error) {
            setError({ general: 'Error updating student data' });
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className='student-reg-form-container'>
            <div className='student-form-container'>
                <h2>Edit Student Profile</h2>
                <form onSubmit={handleSubmit}>
                    <h3>Personal Details</h3>
                    <div className='student-row'>
                        <div className='student-column'>
                            <label>First Name:</label>
                            <input
                                type='text'
                                name='first_name'
                                value={formData.first_name}
                                onChange={handleChange}
                                required
                            />
                            {error.first_name && <span className="error">{error.first_name}</span>}
                        </div>
                        <div className='student-column'>
                            <label>Last Name:</label>
                            <input
                                type='text'
                                name='last_name'
                                value={formData.last_name}
                                onChange={handleChange}
                                required
                            />
                            {error.last_name && <span className="error">{error.last_name}</span>}
                        </div>
                    </div>
    
                    <div className='student-row'>
                        <div className='student-column'>
                            <label>Date of Birth:</label>
                            <input
                                type='date'
                                name='dob'
                                value={formData.dob}
                                onChange={handleChange}
                                required
                            />
                            {error.dob && <span className="error">{error.dob}</span>}
                        </div>
                        <div className='student-column'>
                            <label>Address:</label>
                            <textarea
                                name='address'
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                            {error.address && <span className="error">{error.address}</span>}
                        </div>
                    </div>
    
                    <div className='student-row'>
                        <div className='student-column'>
                            <label>Email:</label>
                            <input
                                type='email'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {error.email && <span className="error"  style={{ color: "red",marginLeft:'10px' }}>{error.email}</span>}
                        </div>
                        <div className='student-column'>
                            <label>Mobile:</label>
                            <input
                                type='text'
                                name='mobile'
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                            />
                            {error.mobile && <span className="error"  style={{ color: "red",marginLeft:'10px' }}>{error.mobile}</span>}
                        </div>
                    </div>
    
                    <div className='student-row'>
                        <div className='student-column'>
                            <label>Parent Name:</label>
                            <input
                                type='text'
                                name='parent_name'
                                value={formData.parent_name}
                                onChange={handleChange}
                                required
                            />
                            {error.parent_name && <span className="error">{error.parent_name}</span>}
                        </div>
                        <div className='student-column'>
                            <label>Profile Image:</label>
                            {student && student.profile_image && (
                                <img
                                    src={`https://myprojecttest.infoxtechnologies.com${student.profile_image}`}
                                    alt="Profile"
                                    style={{ width: '100px', height: '100px' }}
                                />
                            )}
                            <input
                                type='file'
                                name='profile_image'
                                accept="image/*"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
    
                    <h3>Education Details</h3>
                    <div className='student-row'>
                        <div className='student-column'>
                            <label>Academic Percentages:</label>
                            <input
                                type='text'
                                name='academic_percentages'
                                value={formData.academic_percentages}
                                onChange={handleChange}
                                required
                            />
                            {error.academic_percentages && (
                                <span className="error">{error.academic_percentages}</span>
                            )}
                        </div>
                        <div className='student-column'>
                            <label>Joining Year:</label>
                            <input
                                type='text'
                                name='joining_year'
                                value={formData.joining_year}
                                onChange={handleChange}
                                required
                            />
                            {error.joining_year && <span className="error">{error.joining_year}</span>}
                        </div>
                    </div>
    
                    <div className='student-row'>
                        <div className='student-column'>
                            <label>Department:</label>
                            <select
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Department</option>
                                {departments.length > 0 ? (
                                    departments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No departments available</option>
                                )}
                            </select>
                            {error.department && <span className="error">{error.department}</span>}
                        </div>
                        <div className='student-column'>
                            <label>Semester Fee:</label>
                            <input
                                type='text'
                                name='semester_fee'
                                value={formData.semester_fee}
                                onChange={handleChange}
                                required
                            />
                            {error.semester_fee && <span className="error">{error.semester_fee}</span>}
                        </div>
                    </div>
    
                    <button type='submit'>Update Profile</button>
                </form>
            </div>
        </div>
    );
    
};

export default EditStudentProfile;
