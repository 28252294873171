import React from 'react';
import { BrowserRouter as Router, Route, Routes,useLocation } from 'react-router-dom';
import RegistrationForm from './components/admin/RegistrationForm'
import LoginForm from './components/admin/LoginForm';
import Department from './components/admin/Department';
import Navbar from './components/admin/Navbar';
import AdminNavbar from './components/admin/AdminNavbar';
import StudentForm from './components/admin/StudentForm';
import NotificationsPage from './components/admin/NotificationsPage';
import NotificationApprovalPage from './components/admin/NotificationApprovalPage';
import DepartmentList from './components/admin/DepartmentList';
import AccountantHome from './components/admin/AccountantHome';
import ResetPasswordFrom from './components/admin/ResetPasswordForm';
import StudentProfile from './components/admin/StudentProfile';
import StudentList from './components/admin/StudentList';
import EditStudentForm from './components/admin/EditStudentForm';
import BusFeeForm from './components/admin/BusFeeForm';
import BusFeeList from './components/admin/BusFeeList';
import EditBusFeeForm from './components/admin/EditBusFeeForm';
import FineList from './components/admin/FineList';
import FineForm from './components/admin/FineForm';
import AdminHome from './components/admin/AdminHome';
import AdminLayout from './components/admin/AdminLayout';

import PaymentForm from './components/admin/PaymentForm';
import AttendanceEntry from './components/admin/AttendanceEntry';
import AttendanceDetails from './components/admin/AttendanceDetails';

import { NotificationProvider } from './context/NotificationContext';
import AccountantLayout from './components/admin/AccountantLayout';
import PaymentRecords from './components/admin/PaymentRecords';
import FeeStatusForm from './components/admin/FeeStatusForm';
import PaymentReceipt from './components/admin/PaymentReceipt';
import LandingPage from './components/admin/LandingPage';

import AccountantStudentProfiles from './components/admin/AccountantStudentProfiles';
import AccountantStudentDetails from './components/admin/AccountantStudentDetails';
import AdminFeeStatus from './components/admin/AdminFeeStatus';
import AdminPaymentReceipt from './components/admin/AdminPaymentReceipt';
import ApprovedAccountantsPage from './components/admin/ApprovedAccountants';
import AccountantFinePayment from './components/admin/AccountantFinePayment';
import FineReceipt from './components/admin/FineReceipt';
import AccountantFeePayment from './components/admin/AccountantFeePayment';
import AccountantFeeReceipt from './components/admin/AccountantFeeReceipt';

const App = () => {
  const location = useLocation();
  const adminRoutes = ['/admin-home', '/admin/add-department', '/students', '/departments', '/notifications', '/bus-fees', '/fines'];
  const renderNavbar = () => {
    if (location.pathname === '/login' || location.pathname === '/register'){
      return <Navbar />;
    // } else if (adminRoutes.includes(location.pathname)){
    //   return <AdminNavbar />;
    }
  };
  return(
    <div>
      {renderNavbar()}
      
      <Routes>
          
          <Route path="/login" element={<LoginForm />} />
          <Route path="/admin/add-department" element={<AdminLayout><Department/></AdminLayout>}/>
          <Route path="/departments" element={<AdminLayout><DepartmentList/></AdminLayout>}/>
          <Route path="/register" element={<RegistrationForm/>}/>
          <Route path="/admin/register-student" element={<AdminLayout><StudentForm/></AdminLayout>}/>
          <Route path="/students" element={<AdminLayout><StudentList /></AdminLayout>} />
          <Route path="/student/:id" element={<AdminLayout><StudentProfile /></AdminLayout>} />
          
          <Route path="/student/edit/:id" element={<AdminLayout><EditStudentForm /></AdminLayout>} />

          <Route path="/notifications" element={<AdminLayout><NotificationsPage/></AdminLayout>}/>
          <Route path="/notifications/approval" element={<AdminLayout><NotificationApprovalPage /></AdminLayout>} />
          <Route path="/approved-accountants" element={<AdminLayout><ApprovedAccountantsPage/></AdminLayout>}/>

          <Route path="/accountant-home" element={<AccountantLayout><AccountantHome/></AccountantLayout>}/>
          <Route path="/reset-password" element={<AccountantLayout><ResetPasswordFrom/></AccountantLayout>}/>
          <Route path="/bus-fees" element={<AdminLayout><BusFeeForm/></AdminLayout>}/>
          <Route path="/bus-fees/list" element={<AdminLayout><BusFeeList/></AdminLayout>}/>
          <Route path="/bus-fees/edit/:id" element={<AdminLayout><EditBusFeeForm /></AdminLayout>} />

          <Route path="/fines/list" element={<AdminLayout><FineList /></AdminLayout>} />
          <Route path="/fines/add" element={<AdminLayout><FineForm /></AdminLayout>} />
          <Route path="/fines/edit/:id" element={<AdminLayout><FineForm /></AdminLayout>} />

          <Route path="/payments" element={<AdminLayout><PaymentForm/></AdminLayout>} />
          <Route path="/acc-fee-payments" element={<AccountantLayout><AccountantFeePayment/></AccountantLayout>} />
          <Route path="/acc-payments" element={<AccountantLayout><AccountantFinePayment/></AccountantLayout>} />
          <Route path="/fine-receipt" element={<AccountantLayout><FineReceipt/></AccountantLayout>} />
          <Route path="/acc-fee-receipt" element={<AccountantLayout><AccountantFeeReceipt/></AccountantLayout>} />
          <Route path="/payments/records" element={<PaymentRecords/>} />
          <Route path="/fee-status/:admission_number" element={<AccountantLayout><FeeStatusForm/></AccountantLayout>} />
          <Route path="/admin-fee-status/:admission_number" element={<AdminLayout><AdminFeeStatus/></AdminLayout>} />
          <Route path="/payment-receipt/:admission_number" element={<AccountantLayout><PaymentReceipt/></AccountantLayout>} />
          <Route path="/admin-payment-receipt/:admission_number" element={<AdminLayout><AdminPaymentReceipt/></AdminLayout>} />

          <Route path="/attendance/:admissionNumber"  element={<AdminLayout><AttendanceEntry/></AdminLayout>} />
          <Route path="/student/:admissionNumber/attendance" element={<AdminLayout><AttendanceDetails/></AdminLayout>} /> 

          <Route path="/acc-stu-profiles" element={<AccountantLayout><AccountantStudentProfiles/></AccountantLayout>} />
          <Route path="/acc-stu/details/:id" element={<AccountantLayout><AccountantStudentDetails/></AccountantLayout>} />

          

          <Route path="/admin-home" element={<AdminLayout><AdminHome /></AdminLayout>} />
          <Route path="/" element={<LandingPage/>} />
          
          {/* <Route path="/" element={<h2>Welcome! Please <a href="/register">Register</a>  or <a href="/login">Login</a>.</h2>} /> */}
        </Routes>
    </div>
  );
};
export default function WrappedApp() {
  return (
    <NotificationProvider>
       <Router>
       
      <App />
    </Router>
    </NotificationProvider>
   
  );
}
