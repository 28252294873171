import React from 'react';
import { useLocation } from 'react-router-dom';

const FineReceipt = () => {
    const location = useLocation();
    const { receipt } = location.state || {};  // Get receipt data passed via state

    if (!receipt) {
        return <p>No receipt data available.</p>;
    }

    const handlePrint = () => {
        window.print(); // Trigger print dialog
    };

    return (
        <div className='print-payment'>
             <div className="receipt-container">
             <div className="receipt-outer-container">
            <h1>Receipt</h1>

            <div className='receipt-details'>
  <p><strong>Student Name:</strong></p>
  <span>{receipt.studentName}</span>
</div>
<div className='receipt-details'>
  <p><strong>Admission Number:</strong></p>
  <span>{receipt.admissionNumber}</span>
</div>

<h3>Fees Details</h3>    
<div className='receipt-details'>
  <p><strong>Fine:</strong></p>
  <span>{receipt.fineName}</span>
</div>

<div className='receipt-details'>
  <p><strong>Amount Paid:</strong></p>
  <span>{receipt.fineAmount}</span>
</div>

<div className='receipt-details'>
  <p><strong>Date:</strong></p>
  <span>{receipt.paymentDate}</span>
</div>
          
            
            
            <button onClick={handlePrint}>Print Receipt</button>
        </div>
             </div>
            
        </div>
        
    );
};

export default FineReceipt;
