import axios from 'axios';

// const API_URL = 'http://127.0.0.1:8000/api/';
const API_URL = 'https://myprojecttest.infoxtechnologies.com/api/';

// Create an Axios instance with a base URL
const apiClient = axios.create({
  baseURL: API_URL,
});

export const registerAccountant = async (accountantData) => {
  try {
    const response = await apiClient.post('register/accountant/', accountantData);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : 'An unknown error occurred';
  }
};


// Function to log in a user
export const loginUser = async (credentials) => {
  try {
    const response = await apiClient.post('login/', credentials);
    return response.data; // Return the response data
  } catch (error) {
    console.error('Login error:', error);
    throw error.response ? error.response.data : 'An unknown error occurred'; // Handle error response
  }
};


// Function to add a new department
export const addDepartment = async (departmentData) => {
  try {
      const response = await axios.post(`${API_URL}departments/add/`, departmentData);
      return response.data;
  } catch (error) {
      throw error.response.data;
  }
};

export const registerStudent = async (studentData) => {
  try {
      const response = await axios.post(`${API_URL}students/`, studentData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Important for file uploads
          },
      });
      return response.data; // Adjust according to your API response
  } catch (error) {
      console.error('Error registering student:', error);
      throw error.response ? error.response.data : 'An unknown error occurred';
  }
};
export const fetchStudents = async () => {
  const response = await axios.get(`${API_URL}students/list/`);
  return response.data; // Adjust according to your API response
};
export const fetchStudentById = async (admissionNumber) => {
  try {
    const response = await apiClient.get(`students/${admissionNumber}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching student:', error);
    throw error; // Handle or re-throw as needed
  }
};
export const updateStudent = async (admissionNumber, studentData) => {
  try {
    const response = await apiClient.put(`students/${admissionNumber}/update/`, studentData);
    return response.data;
  } catch (error) {
    console.error('Error updating student:', error);
    throw error; // Handle or re-throw as needed
  }
};
export const deleteStudent = async (admissionNumber) => {
  try {
      await apiClient.delete(`students/${admissionNumber}/delete/`);
  } catch (error) {
      console.error('Error deleting student:', error);
      throw error; // Handle or re-throw as needed
  }
};


export const fetchDepartments = async () => {
  try {
      const response = await apiClient.get('/departments/'); // Adjust the endpoint accordingly
      return response.data; // Assuming this is an array of department objects
  } catch (error) {
      console.error('Error fetching departments:', error);
      throw error;
  }
};
// Function to delete a department
export const deleteDepartment = async (id) => {
  try {
    const response = await apiClient.delete(`departments/${id}/delete/`); // Use the apiClient instance
    return response.data; 
  } catch (error) {
    console.error('Error deleting department:', error);
    throw error.response ? error.response.data : 'An unknown error occurred';
  }
};

// Function to update a department
export const updateDepartment = async (id, departmentData) => {
  try {
      const response = await apiClient.put(`departments/${id}/`, departmentData);
      return response.data; // Return updated department data
  } catch (error) {
      console.error('Error updating department:', error);
      throw error.response ? error.response.data : 'An unknown error occurred';
  }
};


// Function to fetch notifications
export const fetchRegisteredAccountants = async () => {
  try {
      const response = await fetch(`${API_URL}accountants/`);
      if (!response.ok) {
          throw new Error('Failed to fetch notifications');
      }
      return await response.json();
  } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error; // Re-throw for handling in the component
  }
};
// export const fetchNotificationDetails = async (id) => {
//   try {
//       const response = await axios.get(`${API_URL}notifications/${id}/`);
//       return response.data;
//   } catch (error) {
//       throw new Error(`Error fetching details for notification ${id}`);
//   }
// };
// Function to approve a registration
export const approveRegistration = async (id) => {
  try {
      const response = await fetch(`${API_URL}accountants/${id}/approve/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
      });
      if (!response.ok) {
          throw new Error('Failed to approve registration');
      }
      return await response.json();
  } catch (error) {
      console.error('Error approving registration:', error);
      throw error;
  }
};


// Function to disapprove a registration
export const disapproveRegistration = async (id) => {
  try {
      const response = await fetch(`${API_URL}accountants/${id}/disapprove/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
      });
      if (!response.ok) {
          throw new Error('Failed to disapprove registration');
      }
      return await response.json();
  } catch (error) {
      console.error('Error disapproving registration:', error);
      throw error;
  }
};
// Fetch approved accountants
export const fetchApprovedAccountants = async () => {
  try {
      const response = await fetch(`${API_URL}approved-accountants/`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error(`Failed to fetch approved accountants. Status: ${response.status}`);
      }

      const data = await response.json(); // Parse the JSON response
      return data;
  } catch (error) {
      console.error('Error fetching approved accountants:', error);
      throw error; // Rethrow the error for the calling component to handle
  }
};

export const fetchUnreadNotifications = async () => {
  const response = await axios.get(`${API_URL}notifications/unread/`);
  return response.data;
};

export const markAllNotificationsAsRead = async () => {
  await axios.patch(`${API_URL}notifications/mark-all-read/`);
};
