import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrash, FaExclamationCircle } from 'react-icons/fa';
import './DepartmentList.css';
const BusFeeList = () => {
    const [busFees, setBusFees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [location, setLocation] = useState('');
    const [busfee, setBusfee] = useState('');
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        const fetchBusFees = async () => {
            try {
                // const response = await axios.get('http://127.0.0.1:8000/api/bus-fees/list/');
                const response = await axios.get('https://myprojecttest.infoxtechnologies.com/api/bus-fees/list/');
                setBusFees(response.data);
            } catch (error) {
                console.error('There was an error fetching the bus fees!', error);
            } finally {
                setLoading(false);
            }
        };
        fetchBusFees();
    }, []);
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this bus fee?')) {
            try {
                // await axios.delete(`http://127.0.0.1:8000/api/bus-fees/${id}/`);
                await axios.delete(`https://myprojecttest.infoxtechnologies.com/api/bus-fees/${id}/`);
                setBusFees(busFees.filter(fee => fee.id !== id));
            } catch (err) {
                console.error('Error deleting bus fee:', err);
                setError('Failed to delete bus fee. Please try again later.');
            }
        }
    };
    if (loading) return <p>Loading...</p>;

    return (
        <div className='display-departments'>
                    <div  className='department-list-container'>
            <h2>BUS FEE LIST</h2>
            <div className='table-wrapper'>
            {busFees.length === 0 ? (
                         <div className="no-data">
                         <FaExclamationCircle className="no-data-icon" />
                         <p>No data found</p>
                     </div>
                    ) : (
            <table>
                <thead>
                    <tr>
                    {/* <th>ID</th> */}
                    <th>Location</th>
                    <th>Bus Fee</th>
                    <th>Actions</th>
                    </tr>
                    
                </thead>
                <tbody>
                    {busFees.map((fee) =>(
                        <tr key={fee.id}>
                            {/* <td>{fee.id}</td> */}
                            <td>{fee.location}</td>
                            <td>{fee.fee}</td>
                            <td>
                            <Link to={`/bus-fees/edit/${fee.id}`} style={{ color: 'blue' }} ><FaEdit /></Link>
                                {/* <button style={{ color: 'blue' }}><FaEdit /></button> */}
                                <button onClick={() => handleDelete(fee.id)} style={{ color: 'red' }}><FaTrash /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    )}
            </div>
            
            {/* <ul>
                {busFees.map((fee) => (
                    <li key={fee.id}>
                        {fee.location}: ${fee.fee}
                        <button onClick={() => handleDelete(fee.id)}>Delete</button>
                        <Link to={`/bus-fees/edit/${fee.id}`}>Edit</Link>
                    </li>
                ))}
            </ul> */}
        </div>
        </div>

    );
};

export default BusFeeList;
