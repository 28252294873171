// AdminLayout.js
import React from 'react';


import './AdminLayout.css'; // Optional: add styles for layout
import AccountantNavbar from './AccountantNavbar';
import AccountantSidebar from './AccountantSidebar';

const AccountantLayout = ({ children }) => {
    
    return (
        <div className="admin-layout">
            <AccountantNavbar />
            <div className="layout-content">
                <AccountantSidebar />
                <main className="main-content">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default AccountantLayout;
