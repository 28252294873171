import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './PaymentForm.css';

const AccountantFinePayment = () => {
    const [students, setStudents] = useState([]);
    const [busFees, setBusFees] = useState([]);
    const [fines, setFines] = useState([]);
    const [studentAdmissionNumber, setStudentAdmissionNumber] = useState('');
    const [semesterFee, setSemesterFee] = useState('');
    const [busFeeId, setBusFeeId] = useState('');
    const [fineId, setFineId] = useState('');
    
    const navigate = useNavigate(); // React Router hook for navigation

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [studentsResponse, busFeesResponse, finesResponse] = await Promise.all([
                    // axios.get('http://127.0.0.1:8000/api/students/list/'),
                    // axios.get('http://127.0.0.1:8000/api/bus-fees/list/'),
                    // axios.get('http://127.0.0.1:8000/api/fines/')
                    axios.get('https://myprojecttest.infoxtechnologies.com/api/students/list/'),
                    axios.get('https://myprojecttest.infoxtechnologies.com/api/bus-fees/list/'),
                    axios.get('https://myprojecttest.infoxtechnologies.com/api/fines/')
                ]);
                setStudents(studentsResponse.data);
                setBusFees(busFeesResponse.data);
                setFines(finesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleStudentChange = (e) => {
        const selectedStudentAdmissionNumber = e.target.value;
        setStudentAdmissionNumber(selectedStudentAdmissionNumber);

        const selectedStudent = students.find(student => student.admission_number === selectedStudentAdmissionNumber);
        if (selectedStudent) {
            setSemesterFee(selectedStudent.semester_fee); 
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const paymentData = {
            student: studentAdmissionNumber, 
            semester_fee: parseFloat(semesterFee),
            bus_fee: busFeeId ? parseInt(busFeeId, 10) : null,
            fine: fineId ? parseInt(fineId, 10) : null,
        };

        try {
            // const response = await axios.post('http://127.0.0.1:8000/api/payments/', paymentData);
            const response = await axios.post('https://myprojecttest.infoxtechnologies.com/api/payments/', paymentData);
            alert('Payment done successfully!');

            // Generate receipt data
            const selectedStudent = students.find(student => student.admission_number === studentAdmissionNumber);
            const selectedFine = fines.find(fine => fine.id === parseInt(fineId, 10));

            const receiptData = {
                studentName: `${selectedStudent.first_name} ${selectedStudent.last_name}`,
                admissionNumber: selectedStudent.admission_number,
                fineName: selectedFine.name,
                fineAmount: selectedFine.amount,
                paymentDate: new Date().toLocaleString(), // Current date and time
            };

            // Navigate to the receipt page and pass the receipt data
            navigate('/fine-receipt', { state: { receipt: receiptData } });

            // Reset the form
            setStudentAdmissionNumber('');
            setSemesterFee('');
            setBusFeeId('');
            setFineId('');
        } catch (error) {
            console.error('Error recording payment:', error.response ? error.response.data : error.message);
            alert('Failed to record payment: ' + (error.response ? error.response.data : error.message));
        }
    };

    return (
        <div className='enter-payment'>
            <div className='payment-form-container'>
                <h2>FINE PAYMENT</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor='student'>Select Student (Admission Number):</label>
                        <select
                            id='student'
                            value={studentAdmissionNumber}
                            onChange={handleStudentChange}
                            required
                        >
                            <option value=''>Choose a student</option>
                            {students.map((student) => (
                                <option key={student.admission_number} value={student.admission_number}>
                                    {student.first_name} {student.last_name} ({student.admission_number})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label htmlFor='fine'>Select Fine:</label>
                        <select
                            id='fine'
                            value={fineId}
                            onChange={(e) => setFineId(e.target.value)}
                        >
                            <option value=''>Choose a fine</option>
                            {fines.map((fine) => (
                                <option key={fine.id} value={fine.id}>
                                    {fine.name}: ${fine.amount}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type='submit'>Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AccountantFinePayment;
