import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create the Notification Context
const NotificationContext = createContext();

// Notification Provider that will wrap around your App component
export const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  // Fetch the unread notifications count from the backend
  const fetchUnreadCount = async () => {
    try {
      // const response = await axios.get('http://127.0.0.1:8000/api/notifications/unread/count/');
      const response = await axios.get('https://myprojecttest.infoxtechnologies.com/api/notifications/unread/count/');
      setUnreadCount(response.data.unread_count); // Assuming the response returns unread_count
    } catch (error) {
      console.error('Error fetching unread notifications count:', error);
    }
  };

  // Optionally, you can fetch the count again when needed (like after login)
  useEffect(() => {
    fetchUnreadCount();
  }, []);
  // Function to update the unread count after marking a notification as read
  const updateUnreadCount = (newUnreadCount) => {
    setUnreadCount(newUnreadCount);
  };
  return (
    <NotificationContext.Provider value={{ unreadCount, fetchUnreadCount, updateUnreadCount  }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the Notification context
export const useNotifications = () => {
  return useContext(NotificationContext);
};
