import React, { useState } from "react";
import { registerAccountant } from "../../api/api";
import "./RegistrationForm.css"; // Import the CSS file
// import logo from "../../assets/images/logo.jpg";
import { Link } from "react-router-dom";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.mobile) newErrors.mobile = "Mobile is required";
    if (!/^\d{10}$/.test(formData.mobile))
      newErrors.mobile = "Mobile number must be 10 digits";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        await registerAccountant({
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          mobile: formData.mobile,
        });
        setIsSubmitted(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
      } catch (error) {
        // If the error is from the backend (like email already in use), show it here
        if (error.response && error.response.data) {
          const { detail } = error.response.data;
          setErrors({ general: detail || "An error occurred during registration." });
        } else {
          setErrors({ general: "Email already in use." });
        }
      }
    }
  };

  return (
    <div className="registration-container">
      <div className="registration-image">
        <div className="registration-left-side">
          <h1>Welcome</h1>
          {/* <img
            src={logo}
            style={{ width: "100px", height: "100px" }}
            alt="Logo"
          /> */}
          {/* <img src="/images/logo.jpg" alt="Logo" style={{ width: "40px", height: "40px" }} /> */}

          <h3>CollSys</h3>
          <p style={{ textAlign: "left", marginLeft: "20px" ,marginRight:'20px'}}>
            Please register and await admin approval.Once your registration is
            approved, you will receive a password via email.{" "}
          </p>
          <Link to="/login">
            <button>Login</button>
          </Link>
          {errors.general && (
              <p className="error-message" style={{marginTop:'30px'}}>{errors.general}</p>
            )}
          {isSubmitted && (
              <p className="success-message" style={{marginTop:"30px"}}>Registration successful!</p>
            )}
        </div>
        
        <div className="registration-form">
          <form onSubmit={handleSubmit}>
          
            <h2>Register Accountant</h2>
            <div>
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name*"
                required
              />
              {errors.firstName && (
                <span className="error-message">{errors.firstName}</span>
              )}
            </div>
            <div>
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name*"
                required
              />
              {errors.lastName && (
                <span className="error-message">{errors.lastName}</span>
              )}
            </div>
            <div>
              <label>Mobile:</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Mobile Number*"
                required
              />
              {errors.mobile && (
                <span className="error-message">{errors.mobile}</span>
              )}
            </div>
            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email*"
                required
              />
             
            </div>
            {errors.email && (
                <span className="error-message" style={{marginLeft:'30px'}}>{errors.email}</span>
              )}
              <br/>
            <button type="submit">Register</button>
           
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
