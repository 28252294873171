import React, { useState } from "react";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/logo.jpg";

import { AiFillHome } from 'react-icons/ai';
import { MdLogin } from 'react-icons/md';
import { FaUserPlus } from 'react-icons/fa';

import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // const handleLinkClick = () => {
  //     setIsOpen(false);
  // };
  return (
    <nav className="navbar-home">
      <div className="navbar-collapse">
     
        {/* <img src={`${process.env.PUBLIC_URL}/logo.jpg`}alt="Logo" style={{ width: "40px", height: "40px", objectFit: "cover" }} /> */}
        <div className="navbar-brand">
          <h2>CollSys</h2>
        </div>
        <button
          className="navbar-toggle"
          onClick={toggleMenu}
          aria-expanded={isOpen}
          aria-label="Toggle menu"
        >
          &#9776; {/* Hamburger icon */}
        </button>
      </div>

      <ul className={`navbar-home-links ${isOpen ? "active" : ""}`}>
        <li className="navbar-home-item">
          <Link className="nav-home-link" to="/">
          <AiFillHome style={{ marginRight: '8px' }} />
          Home
          </Link>
        </li>
        <li className="navbar-home-item">
          <Link className="nav-home-link" to="/login">
          <MdLogin style={{ marginRight: '8px' }} />
          Login
          </Link>
        </li>
        <li className="navbar-home-item">
          <Link className="nav-home-link" to="/register">
          <FaUserPlus style={{ marginRight: '8px' }} />
          Register
          </Link>
        </li>
      </ul>
    </nav>
    
  );
};

export default Navbar;
