// Footer.js
import React from "react";
import "./Footer.css";  // Assuming your CSS file is in the same directory
import { FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <p>&copy; 2024 College Admin System. All rights reserved.</p>
        </div>
        <div className="footer-middle">
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-right">
          <ul>
            <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter style={{ marginRight: '8px', fontSize: '20px', color: 'white' }} />
            </a></li>
            <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook style={{ marginRight: '8px', fontSize: '20px', color: 'white' }} />
            </a></li>
            <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin style={{ marginRight: '8px', fontSize: '20px', color: 'white' }} />
            </a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
