import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './PaymentReceipt.css';
import { FaExclamationCircle } from 'react-icons/fa';

const PaymentReceipt = () => {
  const { admission_number } = useParams();
  const [feeStatus, setFeeStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!admission_number) {
      setError('Admission number is missing');
      return;
    }

    const fetchFeeStatus = async () => {
      try {
        // const response = await axios.get(`http://127.0.0.1:8000/api/payment-receipt/${admission_number}/`);
        const response = await axios.get(`https://myprojecttest.infoxtechnologies.com/api/payment-receipt/${admission_number}/`);
        setFeeStatus(response.data);
      } catch (error) {
        setError(error.response ? error.response.data : 'Something went wrong');
      }
    };

    fetchFeeStatus();
  }, [admission_number]);

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!feeStatus) {
    return <p>Loading...</p>;
  }

  const handlePrint = () => {
    window.print();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Ensures 12-hour format with AM/PM
    });
  };

  return (
    <div className='print-payment'>
      <div className="receipt-container">
        <h1>Payment Receipt</h1>
        <div>
          <div className='receipt-details'>
            <p><strong>Student Name:</strong></p>
            <span> {feeStatus.student.first_name} {feeStatus.student.last_name}</span>
          </div>
          <div className='receipt-details'>
            <p><strong>Admission Number:</strong></p>
            <span>{feeStatus.student.admission_number}</span>
          </div>
          {/* <div className='receipt-details'>
            <p><strong>Semester Fee:</strong></p>
            <span>{feeStatus.student.semester_fee}</span>
          </div> */}
        </div>

        <h3>Payment Details</h3>
        <div className='table-wrapper'>
        {feeStatus.payments.length === 0 ? (
          <div className="no-data">
            <FaExclamationCircle className="no-data-icon" />
            <p>No data found</p>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Semester Fee</th>
                <th>Bus Fee</th>
                <th>Fine</th>
              </tr>
            </thead>
            <tbody>
              {feeStatus.payments.map((payment, index) => (
                <tr key={index}>
                  <td>{formatDate(payment.payment_date)}</td> {/* Format date here */}
                  <td>${payment.fine__amount ? "0" : payment.semester_fee}</td>
                  <td>${payment.bus_fee__fee ? payment.bus_fee__fee : "0"}</td>
                  <td>${payment.fine__amount ? payment.fine__amount : "0"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
</div>
        <h3>Totals</h3>
        <div className='receipt-details'>
          <p><strong>Total Semester Fee:</strong></p>
          <span>${feeStatus.totals.semester_fee}</span>
        </div>
        <div className='receipt-details'>
          <p><strong>Total Bus Fee:</strong></p>
          <span>${feeStatus.totals.bus_fee}</span>
        </div>
        <div className='receipt-details'>
          <p><strong>Total Fine:</strong></p>
          <span> ${feeStatus.totals.fine}</span>
        </div>

        <button onClick={handlePrint}>Print Receipt</button>
      </div>
    </div>
  );
};

export default PaymentReceipt;
