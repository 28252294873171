import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './NotificationsPage.css';
import { useNotifications } from '../../context/NotificationContext';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { updateUnreadCount } = useNotifications();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                // const response = await axios.get('http://127.0.0.1:8000/api/notifications/');
                const response = await axios.get('https://myprojecttest.infoxtechnologies.com/api/notifications/');
                const { unread_notifications, read_notifications } = response.data;
                const allNotifications = [...unread_notifications, ...read_notifications];
                setNotifications(allNotifications);

                if (unread_notifications.length > 0) {
                    await markAllAsRead(unread_notifications.map((notification) => notification.id));
                    updateUnreadCount(0);
                }
            } catch (err) {
                console.error('Error fetching notifications:', err);
                setError('Failed to fetch notifications');
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, [updateUnreadCount]);

    const markAllAsRead = async (ids) => {
        try {
            // await axios.patch('http://127.0.0.1:8000/api/notifications/mark_all_as_read/', {
            await axios.patch('https://myprojecttest.infoxtechnologies.com/api/notifications/mark_all_as_read/', {
                ids,
            });
        } catch (err) {
            console.error('Error marking notifications as read:', err);
        }
    };

    const handleRemoveNotification = async (id) => {
        try {
            // Send a DELETE request to remove the notification
            // await axios.delete(`http://127.0.0.1:8000/api/notifications/${id}/`);
            await axios.delete(`https://myprojecttest.infoxtechnologies.com/api/notifications/${id}/`);
            
            // Update local state
            const updatedNotifications = notifications.filter(
                (notification) => notification.id !== id
            );
            setNotifications(updatedNotifications);
        } catch (err) {
            console.error('Error removing notification:', err);
            alert('Failed to delete the notification. Please try again.');
        }
    };

    if (loading) return <div>Loading notifications...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="admin-notifications-list">
            <div className="notifications-container">
                <h1 className="page-title">Notifications</h1>

                <div className="notifications-section">
                    {notifications.length > 0 ? (
                        <ul className="notifications-list">
                            {notifications.map((notification) => (
                                <li
                                    key={notification.id}
                                    className={`notification-card ${notification.is_read ? 'read' : 'unread'}`}
                                >
                                    <button
                                        className="close-btn"
                                        onClick={() => handleRemoveNotification(notification.id)}
                                    >
                                        &times;
                                    </button>
                                    <p className="notification-message">{notification.message}</p>
                                    <small className="notification-time">
                                        {new Date(notification.created_at).toLocaleString()}
                                    </small>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No notifications available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationsPage;
