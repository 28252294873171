// AdminSidebar.js
import React, { useState,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./AdminSidebar.css"; // Optional: add your CSS for styling
import { FaBars, FaTimes, FaChevronDown, FaChevronUp} from "react-icons/fa";
// import logo from "../../assets/images/logo.jpg";
import { FaPlus } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faHome, faUser, faList, faBus, faMoneyBill, faCog, faBell,faCreditCard,faFileInvoice} from "@fortawesome/free-solid-svg-icons";


const AdminSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isbusfeeDropdownOpen, setIsbusfeeDropdownOpen] = useState(false);
  const [isfineDropdownOpen, setIsfineDropdownOpen] = useState(false);
  const [ispaymentDropdownOpen, setIspaymentDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };
  const toggleBusfeeDropdown = () => {
    setIsbusfeeDropdownOpen(!isbusfeeDropdownOpen); // Toggle dropdown visibility
  };
  const togglefineDropdown = () => {
    setIsfineDropdownOpen(!isfineDropdownOpen); // Toggle dropdown visibility
  };
  const togglepaymentDropdown = () => {
    setIspaymentDropdownOpen(!ispaymentDropdownOpen); // Toggle dropdown visibility
  };
    // Handle the logout logic
    const handleLogout = () => {
        // Clear the authentication data (e.g., JWT token or user data)
        localStorage.removeItem('authToken'); // Or wherever you store the auth token
        sessionStorage.removeItem('authToken'); // Optional, if using session storage instead of localStorage
    
        // Redirect to the login page
        navigate("/login");
      };

      // Detect screen width for mobile/tablet
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <=480) {
        setIsMobile(true); // Mobile view
        setIsCollapsed(true); // Collapsed on mobile by default
      } else {
        setIsMobile(false); // Desktop/tablet view
        setIsCollapsed(false); // Expanded on desktop/tablet by default
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on initial render to set the correct state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

      
  return (
    
    <div className={`admin-sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        {isCollapsed ? <FaBars /> : <FaBars />}
      </button>
      {/* <h2 className={`sidebar-title ${isCollapsed ? 'hidden' : ''}`}>Admin Menu</h2> */}
      <div>
        {!isCollapsed && (
          <div className="sidebar-header">
            {/* <img
                    className="sidebar-logo"
                    src="/assets/images/logo.jpg" // Update the path
                    alt="Logo"
                    style={{
                        opacity: isCollapsed ? 0 : 1,
                        visibility: isCollapsed ? "hidden" : "visible",
                        transition: "opacity 0.3s ease, visibility 0.3s ease",
                    }}
                /> */}
            {/* <img src={logo} className="sidebar-logo" alt="Logo" /> */}
            {/* <img
          src={`http://127.0.0.1:8000/static/images/logo.jpg`} 
          alt="logo"
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
        /> */}
            <h2 className="sidebar-brand">CollSys</h2>
          </div>
        )}
      </div>
      <ul className={`sidebar-links ${isCollapsed ? "hidden" : ""}`}>
        {/* <li>
                    <Link to="/admin/add-department">Add Department</Link>
                </li> */}
        <li>
          <Link to="/admin-home">
          <FontAwesomeIcon icon={faHome} className="logout-icon"  />Admin Home</Link>
        </li>
        <li>
          <Link to="/notifications/approval">
          <FontAwesomeIcon icon={faUser}className="logout-icon" />Accountants</Link>
        </li>
        <li>
          <Link to="/departments">
          <FontAwesomeIcon icon={faList}className="logout-icon" />Department List</Link>
        </li>
        <li className="dropdown-link">
          <div className="dropdown-container">
            <button onClick={toggleDropdown} className="dropdown-button">
            <FontAwesomeIcon icon={faUser}className="logout-icon"style={{marginLeft:'5px'}} /> Students {isDropdownOpen ? <FaChevronUp className="dropdown-arrow"/> : <FaChevronDown className="dropdown-arrow"/>}
            </button>

            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/admin/register-student" className="different-link">
                  <FontAwesomeIcon icon={faUser}className="logout-icon" />Add Student
                  </Link>
                </li>
                <li>
                  <Link to="/students" className="different-link">
                  <FontAwesomeIcon icon={faList}className="logout-icon" /> Student List{" "}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </li>
       

       

       
        



        <li className="dropdown-link">
          <div className="dropdown-container">
            <button onClick={toggleBusfeeDropdown} className="dropdown-button">
            <FontAwesomeIcon icon={faBus}className="logout-icon" style={{marginLeft:'5px'}}/>Bus Fee {isbusfeeDropdownOpen ? <FaChevronUp className="dropdown-arrow" /> : <FaChevronDown className="dropdown-arrow"/>}
            </button>

            {isbusfeeDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/bus-fees" className="different-link">
                  <FontAwesomeIcon icon={faMoneyBill}className="logout-icon" /> Add Bus Fee
                  </Link>
                </li>
                <li>
                  <Link to="/bus-fees/list" className="different-link">
                  <FontAwesomeIcon icon={faList} className="logout-icon"/> Bus Fee List{" "}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </li>



        <li className="dropdown-link">
          <div className="dropdown-container">
            <button onClick={togglefineDropdown} className="dropdown-button">
            <FontAwesomeIcon icon={faMoneyBill} className="logout-icon"style={{marginLeft:'5px'}} /> Fine{isfineDropdownOpen ? <FaChevronUp className="dropdown-arrow-fine"/> : <FaChevronDown className="dropdown-arrow-fine"/>}
            </button>

            {isfineDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/fines/add" className="different-link">
                  <FontAwesomeIcon icon={faMoneyBill}className="logout-icon" />  Add fine
                  </Link>
                </li>
                <li>
                  <Link to="/fines/list" className="different-link">
                  <FontAwesomeIcon icon={faList}className="logout-icon" /> Fine List{" "}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </li>


        {/* Fee payment */}
{/* 

        <li className="dropdown-link">
          <div className="dropdown-container">
            <button onClick={togglepaymentDropdown} className="dropdown-button">
            <FontAwesomeIcon icon={faCreditCard}className="logout-icon"style={{marginLeft:'5px'}} /> Payment {isDropdownOpen ? <FaChevronUp className="dropdown-arrow"/> : <FaChevronDown className="dropdown-arrow"/>}
            </button>

            {ispaymentDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/payments" className="different-link">
                  <FontAwesomeIcon icon={faMoneyBill}className="logout-icon" />Add Payment
                  </Link>
                </li>
                <li>
                  <Link to="/admin-fee-status/:admission_number" className="different-link">
                  <FontAwesomeIcon icon={faFileInvoice}className="logout-icon" />Payment Records{" "}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </li> */}
        <li>
          <Link to="/admin-fee-status/:admission_number">
            <FontAwesomeIcon icon={faFileInvoice} className="logout-icon" />
            Payment Records
          </Link>
        </li>
        <li>
          <Link to="/notifications">
            <FontAwesomeIcon icon={faBell} className="logout-icon" />
            Notifications
          </Link>
        </li>
        <li>
        <div className="sidebar-logout">
        <button onClick={handleLogout} className="sidebar-logout-btn">
          <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon"style={{marginLeft:'5px'}} /> Logout
        </button>
      </div>
        </li>


      </ul>
    </div>
  );
};

export default AdminSidebar;
