import axios from "axios";
import React, { useState } from "react";
import "../../App.css";
const AddDepartment = () => {
  const [name, setName] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // await axios.post("http://127.0.0.1:8000/api/departments/add/", { name });
      await axios.post("https://myprojecttest.infoxtechnologies.com/api/departments/add/", { name });
      alert("Department added successfully");
      setName("");
    } catch (error) {
      console.error(error);
      alert("Error adding Department");
    }
  };
  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Department Name"
          required
          style={{marginBottom : '5px' }}
        />
        
        <button type="submit">Add Depatment</button>
      </form>
    </div>
  );
};
export default AddDepartment;
