import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link here
import axios from 'axios';
import './AttendanceEntry.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AttendanceEntry = () => {
    const { admissionNumber } = useParams(); // Get admission number from URL params
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('Present');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const attendanceData = {
            student: admissionNumber, // Use the admission number from URL params
            date,
            status,
        };

        try {
            // await axios.post(`http://127.0.0.1:8000/api/students/${admissionNumber}/attendance/entry/`, attendanceData);
            await axios.post(`https://myprojecttest.infoxtechnologies.com/api/students/${admissionNumber}/attendance/entry/`, attendanceData);
            alert('Attendance recorded successfully!');
            setDate('');
            setStatus('Present');
        } catch (error) {
            console.error('Error recording attendance:', error.response ? error.response.data : error.message);
            setError('Failed to record attendance: ' + (error.response ? error.response.data.student.join(', ') : 'Please try again.'));
        }
    };

    return (
        <div className='enter-attendance'>
            <h2>Enter Attendance</h2>
            <div className='attendance-record-button'>
                <Link to={`/student/${admissionNumber}/attendance`} className="view-details-link"> {/* Add Link here */}
                    <button>
                        View Details <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                </Link>
            </div>
            <form onSubmit={handleSubmit}>
                <h3> Admission Number: {admissionNumber}</h3>
                <div>
                    <label htmlFor="date">Date:</label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="status">Status:</label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="Present">Present</option>
                        <option value="Absent">Absent</option>
                    </select>
                </div>
                <button type="submit">Submit Attendance</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default AttendanceEntry;
