import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey,faFileInvoice,faBus,faMoneyBill,faSearch, faBell, faSignOutAlt, faHome,faUser,faList,faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from '../../context/NotificationContext';
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";


import "./AdminNavbar.css";

const AccountantNavbar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { unreadCount, fetchUnreadCount } = useNotifications();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false); // New state for sidebar toggle
  const [isbusfeeDropdownOpen, setIsbusfeeDropdownOpen] = useState(false);
  const [isfineDropdownOpen, setIsfineDropdownOpen] = useState(false);
  const [ispaymentDropdownOpen, setIspaymentDropdownOpen] = useState(false);
  
  const navigate = useNavigate();
  const togglepaymentDropdown = () => {
    setIspaymentDropdownOpen(!ispaymentDropdownOpen); // Toggle dropdown visibility
  };
  const handleResetPasswordClick = () => {
    navigate('/reset-password');
};
  useEffect(() => {
    fetchUnreadCount(); // Fetch unread notifications on login or page load
  }, [fetchUnreadCount]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    navigate("/login");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown visibility
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle the sidebar visibility
  };
  const toggleBusfeeDropdown = () => {
    setIsbusfeeDropdownOpen(!isbusfeeDropdownOpen); // Toggle dropdown visibility
  };
  const togglefineDropdown = () => {
    setIsfineDropdownOpen(!isfineDropdownOpen); // Toggle dropdown visibility
  };
  return (
    <nav className="admin-navbar">
        {/* Sidebar toggle button */}
        <button 
        onClick={toggleSidebar} 
        className={`sidebar-toggle-btn ${sidebarOpen ? 'open' : ''}`}
      >
        {sidebarOpen ? <FaBars /> : <FaBars />}
      </button>

      <div className="admin-navbar-brand">
        <h2 style={{ marginLeft: '40px' }}>CollSys</h2>
      </div>
      <div className="navbar-right">
        {/* Search bar */}
        <div className="searchbar">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="search-input"
          />
          <button className="search-button">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <Link to='/notifications' className="notification-link">
          <div className="notification-icon">
            <FontAwesomeIcon icon={faBell} />
            {unreadCount > 0 && <span className="notification-count">{unreadCount}</span>}
          </div>
        </Link>

        <div className="profile" onClick={toggleDropdown}>
          <img
            src="https://www.w3schools.com/howto/img_avatar.png"
            alt="Profile"
            className="profile-img"
          />
        </div>
        {isDropdownOpen && (
          <div className="logout-dropdown-menu">
            <ul>
              <li>
                <button onClick={handleLogout} className="logout-btn">
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Sidebar */}
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
      <div className="sidebar-header">

            <h2 className="sidebar-brand">CollSys</h2>
          </div>
       
        <ul  className={`sidebar-links ${sidebarOpen ? "open" : ""}`}>
        <li>
          <Link to="/accountant-home">
          <FontAwesomeIcon icon={faHome} className="logout-icon"  />Accountant Home</Link>
        </li>
        <li>
          <Link to="/acc-stu-profiles">
          <FontAwesomeIcon icon={faUser}className="logout-icon" />Student Profiles</Link>
        </li>
        <li className="dropdown-link">
          <div className="dropdown-container">
            <button onClick={togglepaymentDropdown} className="dropdown-button">
            <FontAwesomeIcon icon={faCreditCard}className="logout-icon"style={{marginLeft:'5px'}} /> Payment {isDropdownOpen ? <FaChevronUp className="dropdown-arrow"/> : <FaChevronDown className="dropdown-arrow"/>}
            </button>

            {ispaymentDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/acc-fee-payments" className="different-link">
                  <FontAwesomeIcon icon={faMoneyBill}className="logout-icon" />Fee Payment
                  </Link>
                </li>
                <li>
                  <Link to="/acc-payments" className="different-link">
                  <FontAwesomeIcon icon={faMoneyBill}className="logout-icon" />Fine Payment{" "}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </li>

        <li>
          <Link to="/fee-status/:admission_number">
          <FontAwesomeIcon icon={faMoneyBill}className="logout-icon" />Fee Status</Link>
        </li>

        <li>
        <div className="sidebar-logout">
        <button onClick={handleResetPasswordClick} className="sidebar-logout-btn">
          <FontAwesomeIcon icon={faKey} className="logout-icon"style={{marginLeft:'5px'}} /> Reset Password
        </button>
       
      </div>
        </li>

        <li>
        <div className="sidebar-logout">
        <button onClick={handleLogout} className="sidebar-logout-btn">
          <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon"style={{marginLeft:'5px'}} /> Logout
        </button>
      </div>
        </li>

        


       

        </ul>
      </div>
    </nav>
  );
};

export default AccountantNavbar;
