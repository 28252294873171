import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ResetPasswordForm.css';

const ResetPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const passwordRequirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordRequirements.test(password);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validatePassword(newPassword)) {
            setError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
            setLoading(false);
            return;
        }

        try {
            // const response = await axios.post('http://127.0.0.1:8000/api/reset-password/', {
                const response = await axios.post('https://myprojecttest.infoxtechnologies.com/api/reset-password/', {
                email,
                old_password: oldPassword,
                new_password: newPassword,
            });
            setMessage(response.data.detail); // Set success message
            setError(''); // Clear any error
            navigate('/accountant-home'); // Redirect on success
        } catch (error) {
            // setError(error.response ? error.response.data.detail : 'An error occurred');
            setError(
                error.response?.data?.error || 
                error.response?.data?.detail || 
                'An unexpected error occurred'
            );
            setMessage('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="acc-pass-reset">
            <div className="reset-password-form-container">
                <h2>Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Old Password:</label>
                        <input
                            type="password"
                            placeholder="Enter your old password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>New Password:</label>
                        <input
                            type="password"
                            placeholder="Enter your new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Reset Password'}
                    </button>
                </form>
                {error && <p style={{ color: 'red',backgroundColor:'pink',borderRadius:'5px',textAlign:'center' }}>{error}</p>}
                {message && <p style={{ color: 'green' }}>{message}</p>}
            </div>
        </div>
    );
};

export default ResetPasswordForm;
