import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './DepartmentList.css';
import { FaEdit, FaTrash , FaExclamationCircle} from 'react-icons/fa';
const FineList = () => {
    const [fines, setFines] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchFines = async () => {
            try {
                // const response = await axios.get('http://127.0.0.1:8000/api/fines/');
                const response = await axios.get('https://myprojecttest.infoxtechnologies.com/api/fines/');
                setFines(response.data);
            } catch (err) {
                console.error('Error fetching fines:', err);
                setError('Failed to fetch fines. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchFines();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this fine?')) {
            try {
                // await axios.delete(`http://127.0.0.1:8000/api/fines/${id}/`);
                await axios.delete(`https://myprojecttest.infoxtechnologies.com/api/fines/${id}/`);
                setFines(fines.filter(fine => fine.id !== id));
            } catch (err) {
                console.error('Error deleting fine:', err);
                setError('Failed to delete fine. Please try again later.');
            }
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        // <div className='department-list-container'>
        //     <h2>Fine List</h2>
        //     <Link to="/fines/add">Add New Fine</Link>
        //     <ul>
        //         {fines.map(fine => (
        //             <li key={fine.id}>
        //                 {fine.name} - {fine.description} - ${fine.amount}
        //                 <Link to={`/fines/edit/${fine.id}`}>Edit</Link>
        //                 <button onClick={() => handleDelete(fine.id)}>Delete</button>
        //             </li>
        //         ))}
        //     </ul>
        // </div>
        <div className='display-departments'>
                    <div className="department-list-container">
            <h2>FINE LIST</h2>
            {/* <Link to="/fines/add" className="add-fine-button">Add New Fine</Link> */}
            <div className='table-wrapper'>
            {fines.length === 0 ? (
                         <div className="no-data">
                         <FaExclamationCircle className="no-data-icon" />
                         <p>No data found</p>
                     </div>
                    ) : (
            <table className="fine-table">
                <thead>
                    <tr>
                        <th>Fine Name</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {fines.map(fine => (
                        <tr key={fine.id}>
                            <td>{fine.name}</td>
                            <td>{fine.description}</td>
                            <td>${fine.amount}</td>
                            <td>
                                <Link to={`/fines/edit/${fine.id}`}style={{ color: 'blue' }} className="edit-button"><FaEdit /></Link>
                                <button onClick={() => handleDelete(fine.id)}style={{ color: 'red' }} className="delete-button"><FaTrash /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    )}
            </div>
            
        </div>
        </div>

    );
};

export default FineList;
