import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './BusFeeForm.css';

const FineForm = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    // Fetch the fine details if in edit mode
    useEffect(() => {
        const fetchFine = async () => {
            if (id) {
                try {
                    // const response = await axios.get(`http://127.0.0.1:8000/api/fines/${id}/`);
                    const response = await axios.get(`https://myprojecttest.infoxtechnologies.com/api/fines/${id}/`);
                    const fine = response.data;
                    setName(fine.name);
                    setDescription(fine.description);
                    setAmount(fine.amount);
                } catch (error) {
                    console.error('Error fetching fine details:', error);
                }
            }
        };

        fetchFine();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                // Edit existing fine
                // await axios.put(`http://127.0.0.1:8000/api/fines/${id}/`, { name, description, amount });
                await axios.put(`https://myprojecttest.infoxtechnologies.com/api/fines/${id}/`, { name, description, amount });
            } else {
                // Add new fine
                // await axios.post('http://127.0.0.1:8000/api/fines/add/', { name, description, amount });
                await axios.post('https://myprojecttest.infoxtechnologies.com/api/fines/add/', { name, description, amount });
            }
            alert('Fine saved successfully!');
            navigate('/fines/list');
        } catch (error) {
            console.error('There was an error saving the fine!', error);
        }
    };

    return (
        <div className='enter-bus-fee-container'>
            <div className='bus-fee-form-container'>
            <h2>{id ? 'Edit Fine' : 'Add Fine'}</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Fine Name:</label>
                    <input
                        type="text"
                        value={name}
                        placeholder='Enter fine name'
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea
                        value={description}
                        placeholder='Enter text'
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Amount:</label>
                    <input
                        type="number"
                        value={amount}
                        placeholder='Enter fine amount'
                        onChange={(e) => setAmount(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">{id ? 'Update Fine' : 'Add Fine'}</button>
            </form>
        </div>
        </div>
        
    );
};

export default FineForm;
