import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import pic1 from "../../assets/images/pic1.jpg";
import "./LoginPage.css"; // Add your styles here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FaEnvelope, FaLock } from "react-icons/fa";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    if (name === "email") {
      newErrors.email = /\S+@\S+\.\S+/.test(value) ? "" : "Email is invalid";
    } else if (name === "password") {
      newErrors.password =
        // value.length >= 6 ? "" : "Password must be at least 6 characters long";
        value.length >= 6 ? "" : "Password is invalid";
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      // Log formData to check its values
  console.log("Form Data being sent:", formData);
    if (
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((field) => field !== "")
    ) {
      try {
        const response = await axios.post(
          // "http://127.0.0.1:8000/api/login/",
          "https://myprojecttest.infoxtechnologies.com/api/login/",
          formData
        );
        console.log(response.data); // Handle successful login
        // navigate('/accountant-home');
        setIsSubmitted(true);
        navigate(response.data.redirect_url);
      } catch (error) {
        console.error(error); // Handle login errors
        setErrors({ ...errors, login: "Invalid email or password" });
      }
    }
  };

  return (
    <div className="login-container">
      
      <div className="login-image">
      {/* <img
            src="https://www.w3schools.com/howto/img_avatar.png" 
            alt="Profile"
            
          /> */}
        
        {/* <img src={`http://127.0.0.1:8000/static/images/pic1.jpg`} alt="Login" /> */}
        <img src={`https://myprojecttest.infoxtechnologies.com/static/images/pic1.jpg`} alt="Login" />

        {/* <img
          src={`https://myprojecttest.infoxtechnologies.com/static/images/pic1.jpg`} 
          alt="Login"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        /> */}
      </div>
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <h2>Login</h2>
          <div className="icon-container">
            <FontAwesomeIcon icon={faUser} className="login-icon" />
          </div>
          <div className="login-data-container">
            <label>Email:</label>
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              onChange={handleChange}
            />
          </div>
          {errors.email && (
            <span className="error-message" style={{ marginLeft: "100px" }}>
              {errors.email}
            </span>
          )}
          <div className="login-data-container">
            <label>Password:</label>
            <FaLock className="input-icon" />
            <input
              type="password"
              name="password"
              value={formData.password}
              placeholder="Password"
              onChange={handleChange}
            />
            {errors.password && (
              <span className="error-message" style={{marginLeft:'0px'}}>{errors.password}</span>
            )}
          </div>
          <div className="button-container">
            <button type="submit">Login</button>
          
          </div>
      
          
      

         
          {/* <button type="button" onClick={() => alert('Password reset functionality here')}>
            Reset Password
          </button> */}
        </form>
        {isSubmitted && <p>Login successful!</p>}
          {errors.login && (
            <div className="error-message">{errors.login}</div>
          )}
      </div>
    </div>
  );
};

export default LoginForm;
