import React from 'react';
import './AccountantHome.css';
import { FaMoneyBillAlt, FaClipboardList, FaCreditCard, FaChartLine } from 'react-icons/fa';

// Financial Summary Card
const StatCard = ({ title, value }) => (
  <div className="stat-card">
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

// Recent Transactions Card
const TransactionCard = ({ date, description, amount, type }) => (
  <div className="transaction-card">
    <div className="transaction-details">
      <h4>{description}</h4>
      <p className="transaction-date">{date}</p>
    </div>
    <div className={`transaction-amount ${type === 'income' ? 'income' : 'expense'}`}>
      <p>{type === 'income' ? '+' : '-'}${amount}</p>
    </div>
  </div>
);

// Finance Tool Button with Icon
const ToolButton = ({ title, description, icon }) => (
  <div className="tool-button">
    <div className="tool-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const AccountantHome = () => {
  return (
    <div className='acc-home-dashboard'>
      <div className="accountant-homepage">
      {/* Dashboard Overview */}
      <section className="dashboard-overview">
        <h1>Welcome!!!</h1>
        <p>Here’s an overview of the current financial status of CollSys</p>
      </section>

      {/* Financial Summary */}
      <section className="financial-summary">
        <h2>Financial Summary</h2>
        <div className="stats-container">
          <StatCard title="Total Revenue" value="$100,000" />
          <StatCard title="Total Expenses" value="$45,000" />
          <StatCard title="Net Income" value="$55,000" />
          <StatCard title="Payout" value="$100,000" />
        </div>
      </section>

      {/* Recent Transactions */}
      <section className="recent-transactions">
        <h2>Recent Transactions</h2>
        <div className="transactions-container">
          <TransactionCard
            date="Nov 10, 2024"
            description="Student Tuition Payment"
            amount="500"
            type="income"
          />
          <TransactionCard
            date="Nov 12, 2024"
            description="Faculty Salary Payment"
            amount="2000"
            type="expense"
          />
          <TransactionCard
            date="Nov 14, 2024"
            description="Lab Equipment Purchase"
            amount="800"
            type="expense"
          />
        </div>
      </section>

      {/* Finance Tools */}
      <section className="finance-tools">
        <h2>Finance Tools</h2>
        <div className="tools-container">
          <ToolButton 
            title="Manage Budgets" 
            description="Create and manage departmental budgets" 
            icon={<FaMoneyBillAlt />} 
          />
          <ToolButton 
            title="Generate Reports" 
            description="Generate financial reports and statements" 
            icon={<FaClipboardList />} 
          />
          <ToolButton 
            title="View Transactions" 
            description="View and manage all financial transactions" 
            icon={<FaCreditCard />} 
          />
          <ToolButton 
            title="Track Expenses" 
            description="Track and categorize ongoing expenses" 
            icon={<FaChartLine />} 
          />
        </div>
      </section>
    </div>
    </div>
    
  );
};

export default AccountantHome;
