// AdminLayout.js
import React from 'react';
import AdminNavbar from './AdminNavbar'; // Your existing Navbar component
import AdminSidebar from './AdminSidebar'; // Your new Sidebar component
import './AdminLayout.css'; // Optional: add styles for layout

const AdminLayout = ({ children }) => {
    
    return (
        <div className="admin-layout">
            <AdminNavbar />
            <div className="layout-content">
                <AdminSidebar />
                <main className="main-content">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default AdminLayout;
