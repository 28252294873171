import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './BusFeeForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons';

const BusFeeForm = () =>{
    const [location, setLocation] = useState('');
    const [fee, setFee] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // await axios.post('http://127.0.0.1:8000/api/bus-fees/', { location, fee });
            await axios.post('https://myprojecttest.infoxtechnologies.com/api/bus-fees/', { location, fee });

            alert('Bus fee added successfully!');
            setLocation('');
            setFee('');
            navigate('/bus-fees/list');
        } catch (error) {
            console.error('There was an error adding the bus fee!', error);
        }
    };
    return(
        <div className='enter-bus-fee-container'>
            <div className='bus-fee-form-container'>
             <h2 style={{ textAlign: 'center' }}>ADD BUS FEE</h2>
            <form onSubmit={handleSubmit}>
           
            <div>
            
                <label>Location:</label>
                {/* <div className="location-icon">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        
      </div> */}
                <input
                    type="text"
                    value={location}
                    placeholder='Enter location'
                    onChange={(e) => setLocation(e.target.value)}
                    required
                />
            </div>
            <div>
            
                <label>Bus Fee:</label>
                {/* <div className="fees-icon">
        <FontAwesomeIcon icon={faDollarSign} />
        
      </div> */}
                <input
                    type="number"
                    value={fee}
                    placeholder='Enter fee'
                    onChange={(e) => setFee(e.target.value)}
                    required
                />
            </div>
            <br></br>
            <button type="submit">Add Bus Fee</button>
        </form>
        </div>
        </div>
        
    )

}
export default BusFeeForm;