import React, { useEffect, useState } from 'react';
import { fetchRegisteredAccountants, approveRegistration, disapproveRegistration } from '../../api/api';
import './NotificationApprovalPage.css';
import { FaCheck, FaTimes, FaExclamationCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NotificationApprovalPage = () => {
    const [accountants, setAccountants] = useState([]); // State to store list of accountants
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Fetch registered accountants when the component mounts
    useEffect(() => {
        const loadAccountants = async () => {
            try {
                const data = await fetchRegisteredAccountants();
                setAccountants(data);
            } catch (err) {
                setError('Error fetching accountants.');
            } finally {
                setLoading(false);
            }
        };
        loadAccountants();
    }, []);

    // Handle approve registration
    const handleApprove = async (id) => {
        try {
            await approveRegistration(id); // Call API to approve
            setAccountants(accountants.filter((acc) => acc.id !== id)); // Remove from current table
        } catch (error) {
            console.error('Error approving accountant:', error);
        }
    };

    // Handle disapprove registration
    const handleDisapprove = async (id) => {
        try {
            await disapproveRegistration(id); // Call API to disapprove
            setAccountants(accountants.filter((acc) => acc.id !== id)); // Remove from current table
        } catch (error) {
            console.error('Error disapproving accountant:', error);
        }
    };

    // Loading and error handling
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="accountant-approval-container">
            <div className="approval-list-container">
                <h1>Accountant Approval</h1>
                <div className="navigation-links">
                    <Link to="/approved-accountants" className="nav-link">
                        View Approved Accountants
                    </Link>
                    {/* <Link to="/disapproved-accountants" className="nav-link">
                        View Disapproved Accountants
                    </Link> */}
                </div>
                <div className="table-wrapper">
                    {accountants.length === 0 ? (
                        <div className="no-data">
                            <FaExclamationCircle className="no-data-icon" />
                            <p>No data found</p>
                        </div>
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountants.map((accountant) => (
                                    <tr key={accountant.id}>
                                        {/* <td>{accountant.id}</td> */}
                                        <td>{accountant.first_name} {accountant.last_name}</td>
                                        <td>{accountant.email}</td>
                                        <td>{accountant.mobile}</td>
                                        <td>
                                            <button
                                                className="approve-button"
                                                onClick={() => handleApprove(accountant.id)}
                                            >
                                                <FaCheck style={{ color: 'green', marginRight: '5px' }} />
                                                Approve
                                            </button>
                                            <button
                                                className="disapprove-button"
                                                onClick={() => handleDisapprove(accountant.id)}
                                            >
                                                <FaTimes style={{ color: 'red', marginRight: '5px' }} />
                                                Disapprove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationApprovalPage;
