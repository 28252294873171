import React, { useState, useEffect } from "react";
import { registerStudent, fetchDepartments } from "../../api/api";
// import '../../App.css';
import "./StudentForm.css";

const StudentForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    address: "",
    email: "",
    mobile: "",
    parentName: "",
    academicPercentages: "",
    joiningYear: "",
    department: "",
    semesterFee: "",
    profileImage: null, // For handling file input
  });
  const [departments, setDepartments] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    const getDepartments = async () => {
      try {
        const response = await fetchDepartments();
        console.log("Fetched departments:", response);
        setDepartments(response);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    getDepartments();
  }, []); // Empty array means it only runs once on mount

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const validateForm = () => {
    let newErrors = {};

    // First Name
    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
    }

    // Last Name
    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
    }

    // Date of Birth (DOB)
    if (!formData.dob) {
      newErrors.dob = "Date of birth is required";
    }

    // Address
    if (!formData.address) {
      newErrors.address = "Address is required";
    }

    // Email
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // Mobile Number
    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^[0-9]{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits";
    }

    // Parent Name
    if (!formData.parentName) {
      newErrors.parentName = "Parent name is required";
    }

    // Academic Percentages
    if (!formData.academicPercentages) {
      newErrors.academicPercentages = "Academic percentages are required";
    } else if (
      isNaN(formData.academicPercentages) ||
      formData.academicPercentages < 0 ||
      formData.academicPercentages > 100
    ) {
      newErrors.academicPercentages =
        "Academic percentages must be a number between 0 and 100";
    }

    // Joining Year
    if (!formData.joiningYear) {
      newErrors.joiningYear = "Joining year is required";
    } else if (!/^\d{4}$/.test(formData.joiningYear)) {
      newErrors.joiningYear = "Joining year must be a valid year (e.g., 2023)";
    }

    // Department
    if (!formData.department) {
      newErrors.department = "Department is required";
    }

    // Semester Fee
    if (!formData.semesterFee) {
      newErrors.semesterFee = "Semester fee is required";
    } else if (isNaN(formData.semesterFee) || formData.semesterFee <= 0) {
      newErrors.semesterFee = "Semester fee must be a positive number";
    }

    // Profile Image
    if (!formData.profileImage) {
      newErrors.profileImage = "Profile image is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("first_name", formData.firstName);
      formDataToSend.append("last_name", formData.lastName);
      formDataToSend.append("dob", formData.dob);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("mobile", formData.mobile);
      formDataToSend.append("parent_name", formData.parentName);
      formDataToSend.append(
        "academic_percentages",
        formData.academicPercentages
      );
      formDataToSend.append("joining_year", formData.joiningYear);
      formDataToSend.append("department", formData.department);
      formDataToSend.append("semester_fee", formData.semesterFee);
      formDataToSend.append("profile_image", formData.profileImage);

      console.log("Submitting data:", Array.from(formDataToSend.entries()));

      try {
        await registerStudent(formDataToSend);
        console.log("Registration successful");
        alert("Student added successfully!");
        setIsSubmitted(true);
        setFormData({
          firstName: "",
          lastName: "",
          dob: "",
          address: "",
          email: "",
          mobile: "",
          parentName: "",
          academicPercentages: "",
          joiningYear: "",
          department: "",
          semesterFee: "",
          profileImage: null,
        });
      } catch (error) {
        console.error("Error during registration:", error);
        const errorMessage = error.response?.data?.error || "An error occurred";
        setErrors({ general: errorMessage });
      }
    }
  };

  return (
    <div className="student-reg-form-container">
    <div className="student-form-container">
      <h2>ADD NEW STUDENT</h2>
      <form onSubmit={handleSubmit}>
        <h3>Personal Details</h3>
        {errors.general && (
          <span style={{ color: "red" }}>{errors.general}</span>
        )}
        <div className="student-row">
          <div className="student-column">
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name*"
              required
            />
            {errors.firstName && (
              <span style={{ color: "red" }}>{errors.firstName}</span>
            )}
          </div>
          <div className="student-column">
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name*"
              required
            />
            {errors.lastName && (
              <span style={{ color: "red" }}>{errors.lastName}</span>
            )}
          </div>
        </div>
        <div className="student-row">
          <div className="student-column">
            <label>Date of Birth:</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              required
            />
          </div>
          <div className="student-column">
            <label>Address:</label>
            <textarea className="stu-textarea"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              rows="4" // Adjust the number of visible rows
              placeholder="Enter the address*"
              style={{ width: "300px", resize: "vertical" }} // Optional: Styling to control width and resizing
            />
          </div>
        </div>
<div className="student-row">
<div className="student-column">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email*"
            required
          />
          {errors.email && <span style={{ color: "red",marginLeft:'10px' }}>{errors.email}</span>}
        </div>
        <div className="student-column">
          <label>Mobile:</label>
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Phone Number*"
            required
          />
          {errors.mobile && <span style={{ color: "red",marginLeft:'10px' }}>{errors.mobile}</span>} 
        </div>
</div>
        <div className="student-row">
        <div className="student-column">
          <label>Parent Name:</label>
          <input
            type="text"
            name="parentName"
            value={formData.parentName}
            onChange={handleChange}
            placeholder="Parent Name*"
            required
          />
        </div>
        <div className="student-column">
          <label>Profile Image:</label>
          <input type="file" name="profileImage" onChange={handleChange} required/>
        </div>
        </div>
        <h3>Education Details</h3>
        <div className="student-row">
        <div className="student-column">
          <label>Academic Percentages:</label>
          <input
            type="text"
            name="academicPercentages"
            value={formData.academicPercentages}
            placeholder="Enter percentage value*"
            onChange={handleChange}
            required
          />
        </div>
        <div className="student-column">
          <label>Joining Year:</label>
          <input
            type="text"
            name="joiningYear"
            value={formData.joiningYear}
            onChange={handleChange}
            placeholder="Enter year eg:2024*"
            required
          />
        </div>
        </div>
        <div className="student-row">
        <div className="student-column">
          <label>Department:</label>
          <select
            name="department"
            value={formData.department}
            onChange={handleChange}
            required
          >
            <option value="">Select Department</option>
            {departments.map((department) => (
              <option key={department.id} value={department.id}>
                {department.name}
              </option>
            ))}
          </select>
        </div>
        <div className="student-column">
          <label>Semester Fee:</label>
          <input
            type="text"
            name="semesterFee"
            value={formData.semesterFee}
            onChange={handleChange}
            placeholder="Semester fee*"
            required
          />
        </div>
        </div>
        


        <button type="submit">Register</button>
        {/* {isSubmitted && (
          <p style={{ color: "white" ,marginLeft:'350px'}}>Registration successful!</p>
        )} */}
      </form>
    </div>
    </div>

  );
};
export default StudentForm;
