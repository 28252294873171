import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchStudents, deleteStudent } from "../../api/api"; // Adjust the import based on your API file
import "./StudentList.css"; // Import your CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FaExclamationCircle} from 'react-icons/fa';
const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const getStudents = async () => {
      try {
        const response = await fetchStudents(); // Fetch the list of students
        setStudents(response);
      } catch (error) {
        setError("Error fetching students");
      } finally {
        setLoading(false);
      }
    };

    getStudents();
  }, []);

  const handleDelete = async (admissionNumber) => {
    if (window.confirm("Are you sure you want to delete this student profile?")) {
      try {
        await deleteStudent(admissionNumber); // Call the delete function
        setStudents(students.filter((student) => student.admission_number !== admissionNumber)); // Update state
      } catch (error) {
        console.error("Error deleting student:", error);
        alert("Failed to delete student. Please try again.");
      }
    }
  };
 const [showDropdown, setShowDropdown] = useState(null);

  const handleDropdownToggle = (admissionNumber) => {
    setShowDropdown((prev) => (prev === admissionNumber ? null : admissionNumber));
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
return (
    <div className="list-of-students">
      <div className="list-of-students-container">
        <h2>STUDENT LIST</h2>
        {students.length === 0 ? (
                         <div className="no-data">
                         <FaExclamationCircle className="no-data-icon" />
                         <p>No data found</p>
                     </div>
                    ) : (
        <div className="list-of-student-card-container">
          {students.map((student) => (
            <div className="list-of-student-card" key={student.admission_number}>
              <div className="list-of-student-info" >
              <div className="more-options">
                  {/* 3 dots icon for dropdown */}
                  <FontAwesomeIcon 
                    icon={faEllipsisV} 
                    className="more-options-icon" 
                    onClick={() => handleDropdownToggle(student.admission_number)} 
                  />
                  {showDropdown === student.admission_number && (
                    <div className="more-options-dropdown-menu">
                      {/* <Link to={`/student/${student.admission_number}`} className="more-options-dropdown-item">View Profile</Link> */}
                      <Link to={`/student/edit/${student.admission_number}`} className="more-options-dropdown-item"><FontAwesomeIcon icon={faEdit} title="Edit Profile" />
                      <span className="icon-text" >Edit Profile</span></Link>
                      {/* <Link to={`/attendance/${student.admission_number}`} className="more-options-dropdown-item"><FontAwesomeIcon icon={faClipboardList} />
                      <span className="icon-text" >Enter Attendance</span></Link> */}
                      <button onClick={() => handleDelete(student.admission_number)} className="more-options-dropdown-item-delete-btn">
                      <FontAwesomeIcon icon={faTrash} title="Delete Profile" />
                      <span className="icon-text">Delete Profile</span>
                      </button>
                    </div>
                  )}
                </div>
                <img
                  // src={`http://127.0.0.1:8000${student.profile_image}`}
                  src={`https://myprojecttest.infoxtechnologies.com${student.profile_image}`}
                  alt="Profile"
                  style={{ width: "100px", height: "100px" }}
                  className="student-image"
                />
                <h3>
                  {student.first_name} {student.last_name}
                </h3>
                <div className="name-line"></div>
                <div className="view-more">
                <Link to={`/student/${student.admission_number}`} className="view-more-link">
                  
                <FontAwesomeIcon icon={faEye} title="View Profile" />
                <span className="icon-text">View more details</span>
                  </Link>
                </div>
                {/* <div className="icon-links">
                  <Link to={`/student/${student.admission_number}`} className="icon-link view">
                    <FontAwesomeIcon icon={faEye} title="View Profile" />
                    <span className="small-text">View Profile</span>
                  </Link>
                  <Link to={`/student/edit/${student.admission_number}`} className="icon-link edit">
                    <FontAwesomeIcon icon={faEdit} title="Edit Profile" />
                    <span className="small-text">Edit Profile</span>
                  </Link>
                  <Link to={`/attendance/${student.admission_number}`} className="icon-link attendance">
                    <FontAwesomeIcon icon={faClipboardList} />
                    <span className="small-text">Enter Attendance</span>
                  </Link>
                  <button onClick={() => handleDelete(student.admission_number)} className="icon-link delete">
                    <FontAwesomeIcon icon={faTrash} title="Delete Profile" />
                    <span className="small-text">Delete Profile</span>
                  </button>
                </div> */}
              </div>
            </div>
          ))}
        </div>
                    )}
      </div>
    </div>
  );
};

export default StudentList; 