import React, { useEffect, useState } from 'react';
import { fetchApprovedAccountants } from '../../api/api';
import { FaExclamationCircle } from 'react-icons/fa';
const ApprovedAccountantsPage = () => {
    const [approvedAccountants, setApprovedAccountants] = useState([]);

    useEffect(() => {
        const loadApprovedAccountants = async () => {
            const data = await fetchApprovedAccountants();
            setApprovedAccountants(data);
        };
        loadApprovedAccountants();
    }, []);

    return (
        <div className="accountant-approval-container">
             <div className="approval-list-container">
             <h1>Approved Accountants</h1>
            <div className='table-wrapper'>
            {approvedAccountants.length === 0 ? (
                        <div className="no-data">
                            <FaExclamationCircle className="no-data-icon" />
                            <p>No data found</p>
                        </div>
                    ) : (
                        <table>
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {approvedAccountants.map((accountant) => (
                                <tr key={accountant.id}>
                                    {/* <td>{accountant.id}</td> */}
                                    <td>{accountant.first_name} {accountant.last_name}</td>
                                    <td>{accountant.email}</td>
                                    <td>{accountant.mobile}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    )}
            </div>
             </div>
            

        </div>
    );
};

export default ApprovedAccountantsPage;
