import React, { useState, useEffect } from 'react';
import './DepartmentModal.css';

const DepartmentModal = ({ isOpen, onClose, onAdd, onEdit, departmentName, setDepartmentName, editId }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        if (editId) {
            onEdit(editId, departmentName); // Call edit function
        } else {
            onAdd(departmentName); // Call add function
        }
        setDepartmentName('');
        onClose();
    };

    if (!isOpen) return null; // Don't render if not open

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{editId ? 'Edit Department' : 'Add New Department'}</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={departmentName}
                        onChange={(e) => setDepartmentName(e.target.value)}
                        required
                    />
                    <button style={{backgroundColor:'#007bff',color:'white',borderRadius:'5px',width:'120px',marginTop:'20px'}}  type="submit">{editId ? 'Update' : 'Add'}</button>
                    <button style={{backgroundColor:'#6c757d',color:'white',borderRadius:'5px',width:'120px',marginTop:'20px'}}  type="button" onClick={onClose}>Cancel</button>
                </form>
            </div>
        </div>
    );
};

export default DepartmentModal;
