import axios from 'axios';
import Modal from './DepartmentModal';
import './DepartmentList.css';
import React, { useState, useEffect } from 'react';
import { fetchDepartments, addDepartment, updateDepartment, deleteDepartment } from '../../api/api';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { FaPlus, FaExclamationCircle } from 'react-icons/fa';

const DepartmentList = () => {
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [departmentName, setDepartmentName] = useState('');
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        const loadDepartments = async () => {
            try {
                const response = await fetchDepartments();
                setDepartments(response);
            } catch (err) {
                setError('Error fetching departments');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        loadDepartments();
    }, []);

    const handleAddDepartment = async (name) => {
        try {
            const newDepartment = await addDepartment({ name });
            setDepartments([...departments, newDepartment]);
        } catch (error) {
            console.error('Error adding department:', error);
        }
    };

    const handleEdit = (department) => {
        setEditId(department.id);
        setDepartmentName(department.name);
        setIsModalOpen(true); // Open modal for editing
    };

    const handleUpdate = async (id, name) => {
        try {
            await updateDepartment(id, { name });
            setDepartments(departments.map(department => department.id === id ? { ...department, name } : department));
            alert('Department updated successfully');
        } catch (error) {
            console.error(error);
            alert('Error updating department');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDepartment(id);
            setDepartments(departments.filter(department => department.id !== id));
            alert('Department deleted successfully');
        } catch (error) {
            console.error('Error deleting department:', error);
            alert('Error deleting department: ' + (error.response?.data.detail || 'An unknown error occurred'));
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className='display-departments'>
                    <div className='department-list-container'>
            <h2>DEPARTMENT LIST</h2>
            <div className='modal-button'>
                <button onClick={() => { setIsModalOpen(true); setEditId(null); setDepartmentName(''); }}>
                    Add New <FaPlus />
                </button>
            </div>
            <div className='table-wrapper'>
            {departments.length === 0 ? (
                         <div className="no-data">
                         <FaExclamationCircle className="no-data-icon" />
                         <p>No data found</p>
                     </div>
                    ) : (
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {departments.map(department => (
                        <tr key={department.id}>
                            <td>{department.id}</td>
                            <td>{department.name}</td>
                            <td>
                                <button onClick={() => handleEdit(department)} style={{ color: 'blue' }}>
                                    <FaEdit />
                                </button>
                                <button onClick={() => handleDelete(department.id)} style={{ color: 'red' }}>
                                    <FaTrash />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    )}
            </div>
            
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => { setIsModalOpen(false); setEditId(null); setDepartmentName(''); }} 
                onAdd={handleAddDepartment} 
                onEdit={handleUpdate} // Pass edit function to modal
                departmentName={departmentName} 
                setDepartmentName={setDepartmentName} 
                editId={editId} // Pass the edit ID to the modal
            />
        </div>
        </div>

    );
};

export default DepartmentList;
