import React,{useState,useEffect} from 'react';
import { fetchStudents } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
const AccountantStudentProfiles = ()=> {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const getStudents = async () => {
      try {
        const response = await fetchStudents(); // Fetch the list of students
        setStudents(response);
      } catch (error) {
        setError("Error fetching students");
      } finally {
        setLoading(false);
      }
    };

    getStudents();
  }, []);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return(
    <div className="list-of-students">
      <div className="list-of-students-container">
        <h2>Student List</h2>
        <div className="list-of-student-card-container">
          {students.map((student) => (
            <div className="list-of-student-card" key={student.admission_number}>
              <div className="list-of-student-info" >
             
                <img
                  // src={`http://127.0.0.1:8000${student.profile_image}`}
                  src={`https://myprojecttest.infoxtechnologies.com${student.profile_image}`}
                  alt="Profile"
                  style={{ width: "100px", height: "100px" }}
                  className="student-image"
                />
                <h3>
                  {student.first_name} {student.last_name}
                </h3>
                <div className="name-line"></div>
                <div className="view-more">
                <Link to={`/acc-stu/details/${student.admission_number}`} className="view-more-link">
                  
                <FontAwesomeIcon icon={faEye} title="View Profile" />
                <span className="icon-text">View more details</span>
                  </Link>
                </div>
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default AccountantStudentProfiles;