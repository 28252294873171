import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchStudentById } from "../../api/api";
import "./StudentProfile.css";

const StudentProfile = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const getStudent = async () => {
      try {
        const response = await fetchStudentById(id);
        console.log(response); // Log the response to debug
        setStudent(response);
      } catch (error) {
        setError("Error fetching student data");
      } finally {
        setLoading(false);
      }
    };

    getStudent();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!student) return <p>No student found.</p>;

  return (
    <div className="view-student-details">
          <div className="student-profile-card">
      <h2>STUDENT PROFILE</h2>
      {student.profile_image && (
        <img
          // src={`http://127.0.0.1:8000${student.profile_image}`} 
          src={`https://myprojecttest.infoxtechnologies.com${student.profile_image}`} 
          alt="Profile"
          className="profile-image"
        />
      )}
      <div className="student-info">
        
        <div className="personal-details">
          <h3>Personal Details</h3>
          <p>
            <strong>Name:</strong> {student.first_name} {student.last_name}
          </p>
          <p>
            <strong>DOB:</strong> {student.dob}
          </p>
          <p>
            <strong>Address:</strong> {student.address}
          </p>
          <p>
            <strong>Email:</strong> {student.email}
          </p>
          <p>
            <strong>Mobile:</strong> {student.mobile}
          </p>
          <p>
            <strong>Parent Name:</strong> {student.parent_name}
          </p>
        </div>
        <div className="educational-details">
          <h3>Educational Details</h3>
          <p>
            <strong>Admission Number:</strong> {student.admission_number}
          </p>
          <p>
            <strong>Academic Percentages:</strong>{" "}
            {student.academic_percentages}
          </p>
          <p>
            <strong>Joining Year:</strong> {student.joining_year}
          </p>
          <p>
            <strong>Department:</strong> {student.department_info.name}
          </p>
          <p>
            <strong>Semester Fee:</strong> ${student.semester_fee}
          </p>
        </div>
      </div>
    </div>
    </div>

  );
};

export default StudentProfile;
