import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './BusFeeForm.css';
const EditBusFeeForm = () => {
    const [location, setLocation] = useState('');
    const [fee, setFee] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchBusFee = async () => {
            // const response = await axios.get(`http://127.0.0.1:8000/api/bus-fees/${id}/`);
            const response = await axios.get(`https://myprojecttest.infoxtechnologies.com/api/bus-fees/${id}/`);
            setLocation(response.data.location);
            setFee(response.data.fee);
        };
        fetchBusFee();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // await axios.put(`http://127.0.0.1:8000/api/bus-fees/${id}/`, { location, fee });
            await axios.put(`https://myprojecttest.infoxtechnologies.com/api/bus-fees/${id}/`, { location, fee });
            alert('Bus fee updated successfully!');
            navigate('/bus-fees/list');
        } catch (error) {
            console.error('There was an error updating the bus fee!', error);
        }
    };

    return (
        <div className='enter-bus-fee-container'>
            <div  className='bus-fee-form-container'>
            <h2>EDIT BUS FEE</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Location:</label>
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                       
                        required
                    />
                </div>
                <div>
                    <label>Bus Fee:</label>
                    <input
                        type="number"
                        value={fee}
                        onChange={(e) => setFee(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Update Bus Fee</button>
            </form>
        </div>
        </div>
        
    );
};

export default EditBusFeeForm;
