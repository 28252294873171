import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentRecords = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [busFees, setBusFees] = useState({});
    const [fines, setFines] = useState({});
    const [students, setStudents] = useState({});

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                // Fetch the payment records
                const response = await axios.get('https://myprojecttest.infoxtechnologies.com/api/payments/records/');
                setPayments(response.data);
                
                // Fetch associated bus fees and fines
                fetchBusFeesAndFines(response.data);
                
                // Fetch student data
                fetchStudents(response.data);
            } catch (error) {
                console.error('Error fetching payments:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchBusFeesAndFines = (paymentsData) => {
            // Get unique bus fee IDs and fine IDs from the payment records
            const busFeeIds = [...new Set(paymentsData.map(p => p.bus_fee).filter(Boolean))];
            const fineIds = [...new Set(paymentsData.map(p => p.fine).filter(Boolean))];

            // Fetch bus fee details
            axios.get(`https://myprojecttest.infoxtechnologies.com/api/bus-fees/list/?ids=${busFeeIds.join(',')}`)
                .then(response => {
                    const feeMap = response.data.reduce((acc, fee) => {
                        acc[fee.id] = fee;
                        return acc;
                    }, {});
                    setBusFees(feeMap);
                });

            // Fetch fine details
            axios.get(`https://myprojecttest.infoxtechnologies.com/api/fines/?ids=${fineIds.join(',')}`)
                .then(response => {
                    const fineMap = response.data.reduce((acc, fine) => {
                        acc[fine.id] = fine;
                        return acc;
                    }, {});
                    setFines(fineMap);
                });
        };

        const fetchStudents = (paymentsData) => {
            // Get unique student admission numbers from the payment records
            const studentAdmissionNumbers = [...new Set(paymentsData.map(p => p.student).filter(Boolean))];
            console.log('Student Admission Numbers:', studentAdmissionNumbers);

            // Fetch student data by admission numbers
            axios.get(`https://myprojecttest.infoxtechnologies.com/api/students/list/?admission_numbers=${studentAdmissionNumbers.join(',')}`)
                .then(response => {
                    console.log('Fetched Students:', response.data);
                    // Map student data by admission_number for easy lookup
                    const studentMap = response.data.reduce((acc, student) => {
                        acc[student.admission_number] = student;
                        return acc;
                    }, {});
                    setStudents(studentMap);
                    console.log('Mapped Students:', JSON.stringify(studentMap, null, 2)); // Log mapped students
                });
        };

        fetchPayments();
    }, []);

    // Check the structure of the 'students' state
    console.log('Students:', JSON.stringify(students, null, 2));

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="payment-records">
            <h2>Payment Records</h2>
            {payments.length === 0 ? (
                <p>No payment records available.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Student</th>
                            <th>Semester Fee</th>
                            <th>Bus Fee</th>
                            <th>Fine</th>
                            <th>Payment Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => {
                            // Log student details
                            console.log('Payment Student Admission Number:', payment.student); // Log student admission number from payment record
                            const student = students[payment.student]; // payment.student should now match admission_number
                            console.log('Student Data:', student); // Log the student data mapped by admission_number

                            const studentName = student ? `${student.first_name} ${student.last_name}` : 'Student Not Found';

                            return (
                                <tr key={payment.id}>
                                    <td>{payment.id}</td>
                                    <td>{studentName}</td>
                                    <td>${payment.semester_fee}</td>
                                    <td>{payment.bus_fee ? `$${busFees[payment.bus_fee]?.fee}` : 'N/A'}</td>
                                    <td>{payment.fine ? `${fines[payment.fine]?.name}: $${fines[payment.fine]?.amount}` : 'N/A'}</td>
                                    <td>{new Date(payment.payment_date).toLocaleString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default PaymentRecords;
