import React from "react";
import Footer from "./Footer";
import "./Footer.css";
import Navbar from "./Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa";

const Testimonial = ({ text, user }) => (
  <div className="testimonial-item">
    <p>"{text}"</p>
    <p>- {user}</p>
  </div>
);
const LandingPage = () => {
  const Feature = ({ title }) => (
    <div className="feature-item">
      <h3>{title}</h3>
      <p>Short description of the feature.</p>
    </div>
  );
  return (
    <div className="page-container">
      <div className="topbar">
        <div className="left-items">
          <div className="contact-item">
          <a href="tel:+1234567890" ><FaPhone style={{transform:'scaleX(-1)'}}/>+1234567890</a>
          </div>
          <div className="divider"></div> {/* Divider */}
          <div className="contact-item">
            <a href="mailto:support@college.com">✉ support@college.com</a>
          </div>
        </div>
        <div className="right-items">
          <div className="social-icons">
            {/* Social media icons (Using Font Awesome for example) */}
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="1x" />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
          </div>

          {/* Search Bar */}
          <div className="search-bar">
            <input type="text" placeholder="Search..." />
            <button type="button" className="search-btn">
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Navbar />
      </div>
      <div className="page-content">
        {/* <h1>Welcome</h1> */}

        {/* <header className="hero">
        <div className="hero-content">
          <h1>Welcome to [College Name]</h1>
          <p>Empowering Minds, Shaping Futures</p>
          <div className="cta-buttons">
            <button className="cta-btn">Apply Now</button>
            <button className="cta-btn">Request Information</button>
          </div>
        </div>
      </header> */}

        <section className="hero-section">
          <h1>Welcome to CollSys Administration Portal</h1>
          <p>
            Streamlining administrative processes for a more efficient campus
          </p>
          <div className="cta-container">
            <Link to="/login" className="cta-button">
              Log In
            </Link>
            <Link to="/register" className="cta-button">
              Sign Up
            </Link>
          </div>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>What You Can Do</h2>
          <div className="features-container">
            <Feature title="Effortless Registration" />
            <Feature title="Real-time Grades and Academic Records" />
            <Feature title="Manage Attendance" />
            <Feature title="Seamless Communication" />
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how-it-works-section">
          <h2>How It Works</h2>
          <div className="how-it-works-container">
            <div className="step-card">
              <div className="step-icon">👤</div>
              <div className="step-title">Step 1: Create Your Account</div>
              <p className="step-description">
                Sign up with your student or faculty information to access all
                features.
              </p>
            </div>
            <div className="step-card">
              <div className="step-icon">📊</div>
              <div className="step-title">Step 2: Access Records</div>
              <p className="step-description">
                View academic records, attendance, and more with a user-friendly
                interface.
              </p>
            </div>
            <div className="step-card">
              <div className="step-icon">🔔</div>
              <div className="step-title">Step 3: Stay Informed</div>
              <p className="step-description">
                Get notifications and updates to stay on top of important
                information.
              </p>
            </div>
          </div>
        </section>
        <div class="wave-divider">
         
  {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#1b3d2d" d="M0,160L48,138.7C96,117,192,75,288,74.7C384,75,480,117,576,144C672,171,768,181,864,165.3C960,149,1056,107,1152,101.3C1248,96,1344,128,1392,144L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"> 

    </path>
    
    <path fill="#c5e1a5" fill-opacity="0.5" d="M0,224L48,202.7C96,181,192,139,288,122.7C384,107,480,117,576,133.3C672,149,768,171,864,186.7C960,203,1056,213,1152,202.7C1248,192,1344,160,1392,144L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg> */}
</div>
        {/* Testimonials Section */}
        <section className="testimonials-section">
          <h2>What Our Users Say</h2>
          <div className="testimonials-container">
            <Testimonial
              text="This portal made my tasks so much easier!"
              user="Student A"
            />
            <Testimonial text="Efficient and easy to use." user="Professor B" />
            <Testimonial
              text="Saves time and effort for administration."
              user="Admin C"
            />
          </div>
        </section>
      </div>

      <div className="page-footer">
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
