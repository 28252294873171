import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './PaymentForm.css';

const AccountantFeePayment = () => {
    const [students, setStudents] = useState([]);
    const [busFees, setBusFees] = useState([]);
    const [studentAdmissionNumber, setStudentAdmissionNumber] = useState('');
    const [semesterFee, setSemesterFee] = useState('');
    const [busFeeId, setBusFeeId] = useState('');

    const navigate = useNavigate(); // React Router hook for navigation

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [studentsResponse, busFeesResponse] = await Promise.all([
                    // axios.get('http://127.0.0.1:8000/api/students/list/'),
                    // axios.get('http://127.0.0.1:8000/api/bus-fees/list/')
                    axios.get('https://myprojecttest.infoxtechnologies.com/api/students/list/'),
                    axios.get('https://myprojecttest.infoxtechnologies.com/api/bus-fees/list/')
                ]);
                setStudents(studentsResponse.data);
                setBusFees(busFeesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Handle student selection
    const handleStudentChange = (e) => {
        const selectedStudentAdmissionNumber = e.target.value;
        setStudentAdmissionNumber(selectedStudentAdmissionNumber);

        // Auto-populate semester fee based on the selected student
        const selectedStudent = students.find(student => student.admission_number === selectedStudentAdmissionNumber);
        if (selectedStudent) {
            setSemesterFee(selectedStudent.semester_fee);
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const paymentData = {
            student: studentAdmissionNumber,
            semester_fee: parseFloat(semesterFee),
            bus_fee: busFeeId ? parseInt(busFeeId, 10) : null,
        };

        try {
            // Make API request to record the payment
            // const response = await axios.post('http://127.0.0.1:8000/api/payments/', paymentData);
            const response = await axios.post('https://myprojecttest.infoxtechnologies.com/api/payments/', paymentData);
            alert('Payment done successfully!');

            // Generate receipt data
            const selectedStudent = students.find(student => student.admission_number === studentAdmissionNumber);
            const selectedBusFee = busFees.find(busFee => busFee.id === parseInt(busFeeId, 10));

            const receiptData = {
                studentName: `${selectedStudent.first_name} ${selectedStudent.last_name}`,
                admissionNumber: selectedStudent.admission_number,
                semesterFee: selectedStudent.semester_fee,
                busFee: selectedBusFee ? selectedBusFee.fee : 'N/A',
                paymentDate: new Date().toLocaleString(), // Current date and time
            };

            // Navigate to the receipt page and pass the receipt data
            navigate('/acc-fee-receipt', { state: { receipt: receiptData } });

            // Reset the form
            setStudentAdmissionNumber('');
            setSemesterFee('');
            setBusFeeId('');
        } catch (error) {
            console.error('Error recording payment:', error.response ? error.response.data : error.message);
            alert('Failed to record payment: ' + (error.response ? error.response.data : error.message));
        }
    };

    return (
        <div className="enter-payment">
            <div className="payment-form-container">
                <h2>FEE PAYMENT</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="student">Select Student (Admission Number):</label>
                        <select
                            id="student"
                            value={studentAdmissionNumber}
                            onChange={handleStudentChange}
                            required
                        >
                            <option value="">Choose a student</option>
                            {students.map((student) => (
                                <option key={student.admission_number} value={student.admission_number}>
                                    {student.first_name} {student.last_name} ({student.admission_number})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="semesterFee">Semester Fee:</label>
                        <input
                            type="number"
                            id="semesterFee"
                            value={semesterFee} // Auto-populated
                            readOnly // Make it read-only
                        />
                    </div>

                    <div>
                        <label htmlFor="busFee">Select Bus Fee:</label>
                        <select
                            id="busFee"
                            value={busFeeId}
                            onChange={(e) => setBusFeeId(e.target.value)}
                        >
                            <option value="">Choose a bus fee</option>
                            {busFees.map((busFee) => (
                                <option key={busFee.id} value={busFee.id}>
                                    {busFee.location}: ${busFee.fee}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AccountantFeePayment;
