import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminPaymentReceipt from './AdminPaymentReceipt';
import './FeeStatusForm.css';

const AdminFeeStatus = () => {
  const [admissionNumber, setAdmissionNumber] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSearch = async () => {
    try {
      // Perform your GET request here to fetch fee status (optional)
      // const response = await axios.get(`http://127.0.0.1:8000/api/fee-status/${admissionNumber}/`);
      const response = await axios.get(`https://myprojecttest.infoxtechnologies.com/api/fee-status/${admissionNumber}/`);
      // Handle success (you can store the fee status in state if needed)
      setError(null);
      // After fetching, navigate to the payment receipt page with the admission number
      navigate(`/admin-payment-receipt/${admissionNumber}`);
   
    } catch (error) {
      setError(error.response ? error.response.data : 'Something went wrong');
    }
  };

  return (
    <div className='search-fee-status'>
          <div className='fee-status-container'>
      <h2>PAYMENT DETAILS</h2>
      <input
        type="text"
        value={admissionNumber}
        onChange={(e) => setAdmissionNumber(e.target.value)}
        placeholder="Enter admission number"
      />
      <button onClick={handleSearch}>Search</button>
     
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
    </div>

  );
};

export default AdminFeeStatus;



