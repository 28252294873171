import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './DepartmentList.css'

const AttendanceDetails = () => {
    const { admissionNumber } = useParams();
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchAttendance = async () => {
            try {
                // const response = await axios.get(`http://127.0.0.1:8000/api/students/${admissionNumber}/attendance/`);
                const response = await axios.get(`https://myprojecttest.infoxtechnologies.com/${admissionNumber}/attendance/`);
                setAttendanceRecords(response.data);
            } catch (err) {
                setError('Error fetching attendance records.');
            } finally {
                setLoading(false);
            }
        };

        fetchAttendance();
    }, [admissionNumber]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className='department-list-container'>
            <h2>Attendance Records for Admission Number: <span className="highlight">{admissionNumber}</span></h2>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {attendanceRecords.map(record => (
                        <tr key={record.id}>
                            <td>{record.date}</td>
                            <td>{record.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AttendanceDetails;
