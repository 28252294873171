import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import "./AdminHome.css";
import { FaCalendarAlt, FaBullhorn, FaUserGraduate } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faChalkboardTeacher,
  faUserPlus,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import "./Footer.css";
// import Calendar from 'react-calendar';

const AdminHome = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const onDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date);
  };
  // Component for Quick Stats
  const StatCard = ({ title, value, icon }) => (
    <div className="stat-card">
      <div className="stat-icon">{icon}</div>
      <div className="stat-info">
        <h3>{title}</h3>
        <p>{value}</p>
      </div>
    </div>
  );

  // Component for Admin Tools
  const ToolButton = ({ title, description }) => (
    <div className="tool-button">
      <h3 className="tool-button-title">{title}</h3>
      <p className="tool-button-description">{description}</p>
    </div>
  );
  const UpdateCard = ({ icon, title, date, description }) => (
    <div className="update-card">
      <div className="update-icon">{icon}</div>
      <div className="update-content">
        <h3>{title}</h3>
        <p className="update-date">{date}</p>
        <p className="update-description">{description}</p>
      </div>
    </div>
  );
  return (
    <div className="admin-home-dashboard">
      <div className="admin-homepage">
      {/* Dashboard Overview */}
      <section className="dashboard-overview">
        <h1>Welcome!!!</h1>
        <p>
          Here’s a quick overview of the current status of CollSys
          administration.
        </p>
      </section>
      <div className="admin-dashboard">
        <section className="quick-stats">
          <h2>Quick Stats</h2>

          <div className="stats-container">
            <StatCard
              title="Total Students"
              value="1,234"
              icon={
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  className="stat-icon total-students"
                />
              }
            />
            <StatCard
              title="Faculty Members"
              value="89"
              icon={
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="stat-icon faculty-members"
                />
              }
            />
            <StatCard
              title="New Admissions"
              value="45"
              icon={
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="stat-icon new-admissions"
                />
              }
            />
            <StatCard
              title="Events Scheduled"
              value="5"
              icon={
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="stat-icon events-scheduled"
                />
              }
            />
          </div>
        </section>

        {/* Recent Updates as a List */}
        <section className="recent-updates">
          <h2>Recent Updates</h2>
          <ul className="updates-list">
            <li className="update-item">

              <span className="update-icon faculty-meet">
                <FaCalendarAlt />
              </span>
              <div className="update-details">
                <h3 className="update-title">Faculty Meeting</h3>
                <p className="update-date">Nov 30, 2024</p>
                <p className="update-description">
                  New faculty meeting scheduled.
                </p>
              </div>
            </li>
            <li className="update-item">
              <span className="update-icon exam-schedule">
                <FaBullhorn />
              </span>
              <div className="update-details">
                <h3 className="update-title">Exam Schedule</h3>
                <p className="update-date">Dec 10-20, 2024</p>
                <p className="update-description">
                  Final exams set for December.
                </p>
              </div>
            </li>
            <li className="update-item">
              <span className="update-icon spring-add">
                <FaUserGraduate />
              </span>
              <div className="update-details">
                <h3 className="update-title">Spring Admissions</h3>
                <p className="update-date">Open Now</p>
                <p className="update-description">
                  Admissions open for Spring 2025.
                </p>
              </div>
            </li>
            <li className="update-item">
              <span className="update-icon lib-hrs">
                <FaCalendarAlt />
              </span>
              <div className="update-details">
                <h3 className="update-title">Library Hours</h3>
                <p className="update-date">Dec 15, 2024</p>
                <p className="update-description">
                  Library hours extended during finals week.
                </p>
              </div>
            </li>
          </ul>
        </section>
      </div>

      <section className="admin-tools">
        <h2>Admin Tools</h2>
        <div className="tools-container">
          <ToolButton
            title="Manage Students"
            description="View, edit, or delete student records"
          />
          <ToolButton
            title="Manage Faculty"
            description="Manage faculty profiles and assignments"
          />
          <ToolButton
            title="Class Scheduling"
            description="Create and manage class schedules"
          />
          <ToolButton
            title="Announcements"
            description="Post campus-wide announcements"
          />
        </div>
      </section>
      {/* Calendar Section */}
      {/* <div className="calendar-section">
                <h2>Calendar</h2>
                <Calendar onChange={onDateChange} value={selectedDate} />
            </div> */}
      {/* <div className="page-footer">
        <Footer />
      </div> */}
    </div>
    </div>
    
  );
};
export default AdminHome;
